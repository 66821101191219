export const Spanish = {
  lang: 'es',
  wizard: {
    createAccount: 'Crea tu cuenta gratuita',
    noCreditCard: 'Sin tarjeta de crédito, cancela en cualquier momento',
    name: 'Nombre',
    lastName: 'Apellido',
    email: 'Correo Electrónico',
    companyName: 'Empresa',
    role: 'Cargo',
    dialCode: 'País',
    phoneNumber: 'Teléfono',
    fullNumber: 'País y teléfono',
    password: 'Contraseña',
    saveButton: 'Enviar',
    roles: [
      'CEO / Presidente',
      'Director de Operaciones',
      'Director de Seguridad',
      'Finanzas - Profesional',
      'Finanzas - VP / Gerente',
      'IT - Profesional',
      'IT - VP / Gerente',
      'Marketing',
      'Operaciones - Profesional',
      'Operaciones - VP / Gerente',
      'Ventas - Profesional',
      'Ventas - VP / Gerente',
      'Servicios - Profesional',
      'Servicios - VP / Gerente',
      'Consultor',
      'Profesor',
      'Estudiante',
      'Otro',
    ],
    captchaError: 'Captcha no ha sido validado',
    inputError: 'Completar campo',
    emailExistsError: 'El correo electrónico se encuentra en uso',
    emailError: 'Correo electrónico inválido',
    emailInfo: 'Con este correo puedes entrar a la aplicación móvil y web',
    passwordError: 'Debe contener al menos 6 caracteres',
    phoneNumberError: 'Solo números',
    privacyMessage: 'Haciendo click aceptas que estás de acuerdo con la ',
    privacy: 'Política de Privacidad',
    preparingForm: 'Estamos creando tu cuenta y preparando el formulario',
    preparingForm2: 'Esto puede tardar unos segundos',
    preparingForm3: 'Estamos preparando el formulario',
    userHasAccount: '¿Ya tienes cuenta?',
    login: 'Inicia sesión',
  },
  wizardFinish: {
    buildYourFirstForm: 'Crea tu primer formulario móvil',
    formTemplates: 'Plantillas de formularios',
    createForm: 'Crear formulario',
    welcome: '¡Bienvenido!',
    welcomeDescription1: 'A partir de ahora, podrás ',
    welcomeDescription2:
      'ingresar a la plataforma web y móvil utilizando las credenciales recien ingresadas.',
    welcomeDescription3:
      'Recuerda que para comenzar a recopilar datos, simplemente debes descargar la aplicación desde Google Play o App Store en tu teléfono o tableta.',
  },
  task_assigner: {
    assigned: 'Asignadas',
    completed: 'Completas',
    pending: 'En curso',
    incomplete: 'Incompletas',
    states: {
      completed: 'Completa',
      pending: 'En curso',
      incomplete: 'Incompleta',
      rejected: 'Rechazada',
      programmed: 'Programada',
      sent: 'Enviada',
    },
    cards: {
      totalTasks: 'Total tareas',
      completed: 'Completas',
      pending: 'En curso',
      incomplete: 'Incompletas',
      rejected: 'Rechazadas',
      programmed: 'Programadas',
      sent: 'Enviadas',
    },
    filters: 'Filtros',
    new_task: 'Nueva Tarea',
    import: 'Importar',
    edit_task: 'Editar Tarea',
    tasks: 'Tareas',
    massive_search: 'Buscar por usuario, formulario o lugar...',
    user: 'Usuario',
    form: 'Formulario',
    place: 'Lugar',
    date: 'Fecha',
    date_and_time: 'Fecha y Hora',
    gap: 'Duración',
    state: 'Estado',
    save: 'Guardar',
    users: 'Usuarios',
    description: 'Descripción',
    hour: 'Hora',
    alert: 'alerta',
    delete: 'Eliminar',
    cancel: 'cancelar',
    accept: 'aceptar',
    notify: 'Notificar',
    notify_user: 'Quieres notificar a ',
    sure: '¿Estás seguro?',
    delete_task: 'Vas a borrar una tarea',
    delete_tasks: 'Quieres borrar estas tareas: ',
    reset: 'Restablecer',
    old_version: 'Version Antigua',
    task_created: 'Tarea Creada',
    our_problem: 'Tenemos un problema',
    task_update: 'Tarea actualizada con éxito',
    export: 'Exportar',
    date_necessary: 'Es necesaria una fecha',
    required: 'Requerido',
    blanck: 'Hay campos vacios',
    notification_sent: 'Notificación enviada con éxito',
    notifications_sent: 'Notificaciones enviadas con éxito',
    tasks_delete:
      'Tareas eliminadas con éxito. Espera unos minutos para ver los cambios reflejados.',
    task_delete: 'Tarea eliminada con éxito',
    new_place: 'Nuevo lugar',
    start: 'Comienzo',
    end: 'Final',
    history: 'Historial',
    actions: 'Acciones',
    dobleClick: 'Doble click en la tarea = abrir detalles',
    shiftMouse: 'shift + rueda del ratón = mover cronograma izquerda/derecha',
    altMouse: 'alt + rueda del ratón = zoom in/out',
    timeline: 'Cronograma',
    taskAssignID: 'ID tarea asignada',
    selected_range: 'Rango seleccionado',
    notifications: 'Agregar notificaciones',
    add_notification: 'Agrega una notificación',
    seconds: 'segundos',
    minutes: 'minutos',
    hours: 'horas',
    days: 'días',
    weeks: 'semanas',
    months: 'meses',
    years: 'años',
    notification: 'Notificación',
    email: 'Email',
    max_one_date: 'Máximo una fecha en modo edición',
    max_one_user: 'Máximo un usuario en modo edición',
    no_location_warning: 'No se ha especificado un lugar, o este puede haber sido eliminado.',
    showing_results_for: 'Mostrando resultados para',
    task: 'Tarea',
    no_tasks_available: 'No tienes tareas disponibles en este momento',
    gap_hours: 'Duración de las tareas en horas',
    allow_late_submission:
      'Permitir responder la tarea posterior a que se haya cumplido la fecha establecida',
    add_tasks: 'Agregar tareas',
    add_tasks_caption:
      'Puedes agregar tareas rápidamente separándolas con comas “,” o presionando Enter',

    configuration: 'Configuración',
    voluntary_task:
      'Hacer voluntaria esta tarea (Todos los usuarios pueden aceptar/rechazar la tarea)',
    mandatory_task: 'Hacer obligatoria esta tarea (Todos los usuarios deben responder la tarea)',
    notification_type: 'Tipo de notificación',
    notification_time: 'Tiempo',
    notification_period: 'Periodo de tiempo',
    app_notification: 'Notificación en app',
    confirmation_title_prefix: '¿Estás seguro que quieres crear ',
    confirmation_title_suffix: ' tareas?',
    confirmation_title_suffix_one: 'tareas por',
    confirmation_title_suffix_two: 'días?',
    edit_confirmation_title: '¿Estás seguro de que deseas editar esta tarea?',
    success_message:
      '¡Tareas creadas exitosamente! Por favor, espera unos segundos para que se reflejen. Recuerda refrescar la página para visualizarlas.',
    update_success_message: '¡Tarea actualizada exitosamente!',
    forms_info: 'Si seleccionas varios formularios, crearás varias tareas con los seleccionados',
    edit: 'Editar',
    show_answer: 'Ver respuesta',
    notify_title: '¿Estás seguro que quieres notificar al siguiente usuario?',
    bulk_notify_title: '¿Estás seguro que quieres notificar a los siguientes usuarios?',
    delete_title: '¿Estás seguro que quieres eliminar la tarea?',
    delete_description: 'Se eliminará  y no la podrás recuperar',
    bulk_delete_title_one: '¿Estás seguro que quieres eliminar',
    bulk_delete_title_two: 'tareas?',
    bulk_delete_description:
      'Si las eliminas, desaparecerá de la lista de tareas pendientes de los usuarios y no las podrán responder',
    bulk_actions: 'Acciones masivas',
    creation_date: 'Fecha creación',
    start_date: 'Fecha inicio',
    answers: 'Respuestas',
    duration: 'Duración:',
    answered: 'respondieron',
    out_of_time: 'Fuera de tiempo',
    late: 'tarde',
    no_location: 'Sin lugar',
    task_does_not_exist: 'La tarea no existe',
    location_copied: 'Lugar copiado al portapapeles',
    completed_tasks_cannot_be_deleted: 'Las tareas completadas no pueden ser eliminadas',
  },
  form_answer: {
    answer_again: 'Responder de nuevo',
    submit_button: 'Enviar',
    edit_button: 'Editar',
    incomplete_edit_alert: 'Debes responder todas las preguntas requeridas',
    incomplete_submit_alert: 'Aún no subes ninguna respuesta',
    error_submit_alert: 'Hubo un error al intentar subir tu respuesta',
    error_edit_alert: 'Hubo un error al intentar editar tu respuesta',
    no_edit_alert: 'No has editado tu respuesta aún',
    no_answer_alert: 'Tu respuesta esta vacía',
    required_question: 'Esta pregunta es obligatoria',
    no_options: 'No hay opciones',
    invalid_question_type_1: 'Pregunta de tipo ',
    invalid_question_type_2:
      'Este tipo de pregunta no puede ser respondida/editada en nuestra plataforma web. Por favor descargue la aplicación móvil de DataScope.',
    some_invalid_question_types:
      'Advertencia: Este formulario incluye tipos de pregunta no válidos para la versión web de DataScope.',
    download_mobile: 'Descarga nuestra aplicación móvil aquí',
    download_mobile_link: 'https://datascope.page.link/3N7P',
    answer_placeholder: 'Tu respuesta',
    simple_text_placeholder: 'Tu respuesta de texto',
    text_rut_placeholder: 'Tu RUT de respuesta',
    text_container_placeholder: 'Tu ID de contenedor',
    invalidRutError: 'RUT inválido',
    invalidContainerError: 'ID de contenedor inválido',
    text_email_placeholder: 'Tu email de respuesta',
    select_your_date: 'Selecciona una fecha',
    select_your_datetime: 'Selecciona fecha y hora',
    select_your_time: 'Selecciona un horario',
    datetime_errors: {
      invalidDate: 'Fecha inválida',
      minDate: 'La fecha no alcanza el mínimo permitido',
      maxDate: 'La fecha supera el máximo permitido',
    },
    add_repeatable_questions: 'Agregar otro grupo de preguntas',
    signature_type: 'Firma',
    photo_type: 'Foto',
    barcode_type: 'Código de barras',
    filter_elements: 'Buscar elemento...',
    upload_image: 'Subir Imagen',
    no_image_loaded: 'No se ha subido ninguna imágen',
    upload_file: 'Subir Archivo',
    unsupported_file_type: 'Este tipo de archivo no está permitido para esta pregunta',
    exceeded_max_number_files:
      'Has excedido la cantidad máxima de archivos permitidos para esta pregunta',
    image_max_size_exceeded: 'Una o más de sus archivos superan el límite de 10MB',
    image_max_amount_exceeded_part_one: 'Puedes subir hasta',
    image_max_amount_exceeded_part_two: 'imágenes por comentario',
    image_upload_error: 'Ocurrió un error subiendo las imágenes, por favor intente nuevamente',
    no_signature_loaded: 'No has cargado ninguna firma',
    clear: 'Limpiar',
    save_canvas: 'Guardar',
    modify_signature: 'Modificar',
    next: 'Siguiente',
    previous: 'Atrás',
    success_message: 'Hemos recibido tu respuesta correctamente',
    download_app_suggestion:
      '¿Buscando una forma más conveniente y más rápida de responder tus formularios?',
    confirm_section_change:
      'Esta sección tiene una pregunta obligatoria que aún no ha respondido correctamente. ¿Desea continuar de todos modos?',
    load_more_data: 'Cargar más datos',
    no_data_left: 'No quedan datos para cargar',
    clear_all: 'Borrar todo',
    no_header_warning:
      'El creador de esta pregunta no especificó los encabezados de lista de las listas relacionadas.',
    more_answers_alert: 'Baja para ver más',
    subform_limit: 'Límite de grupos de preguntas alcanzado',
    subform_min: 'Por favor agrega el número mínimo de respuestas',
    multi_option_placeholder: 'Selecciona una opción',
    image: 'Imagen',
    download: 'Descargar',
    multiple_options: 'Selecciona múltiples opciones',
    group: 'Grupo',
    start: 'Comienzo',
    end: 'Fin',
    invalid_date: 'Fecha inválida',
    invalid_time: 'Hora inválida',
    optionComments: {
      addNote: 'Añadir una nota',
      removeNote: 'Eliminar nota',
      writeComment: 'Escribe un comentario...',
      requiredNote: 'Comentario requerido',
      addNoteMobile: 'Nota',
      addImage: 'Adjuntar Imagen',
      addImageMobile: 'Imagen',
      removeNoteMobile: 'Eliminar',
      requiredImage: 'Imagen requerida',
      deleteImageWarningTitle: '¿Estás seguro que quieres eliminar esta imagen?',
      deleteImageWarningSubTitle: 'Una vez que la elimines, no la podrás recuperar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
    },
    drag_drop_file: 'Arrastra y suelta aquí tus archivos',
    or_browse_for_file: 'o búscalos en tu computador',
    missingRequiredAnswersAlert:
      '¡Ups! Parece que aún faltan algunas preguntas por responder. Por favor, completa todas las preguntas requeridas para poder continuar.',
    totalPercentage: 'Porcentaje total',
    enterSampleTotal: 'Ingresa el total de la muestra',
    forbidden_access:
      'Parece que no tienes permiso para editar este formulario. Solicítalo para poder realizar modificaciones',
    automated_signature_on_response:
      'Este formulario tiene firma configurada. La solicitud se enviará a los usuarios asignados al finalizar el formulario.',
    electronic_signature: 'Firma electrónica',
    sign_now: 'Firmar ahora',
    sign_later: 'Firmar más tarde',
    sign_modal_description: 'Este formulario requiere tu firma, ¿quieres firmar ahora?',
    signature_creation_failed: 'Hubo un error al firmar el formulario',
    redirecting_to_answer_view: 'Redirigiendo a respuesta firmada...',
    redirecting_to_signatures: 'Redirigiendo a tus firmas...',
  },
  percentage: 'Porcentaje',
  build_your_own_form: 'Construye tu propio formulario con DataScope',
  back_to_taskForms: 'Volver a lista de formularios',
  date_fns: 'es',
  yes: 'Sí',
  no: 'No',
  insert_value: 'Inserte un valor',
  and: 'Y',
  invalidEmail: 'Email inválido',
  ok: 'OK',
  group: 'Grupo',
  folder: 'Folder',
  clearAll: 'Borrar todos',
  users: 'Usuarios',
  forms: 'Formularios',
  actions: 'Acciones',
  select: 'Seleccionar',
  selectAll: 'Seleccionar todos',
  form_builder: {
    formName: 'Nombre del formulario',
    saveForm: 'Guardar',
    title: 'Título',
    titleDescription: 'Título corto para identificar la pregunta. Aparece al exportar los datos.',
    instruction: 'Descripción',
    instructionDescription:
      'Texto más extenso para mostrar en la app. No aparece al exportar datos (Opcional)',
    questionType: 'Cambiar tipo de pregunta',
    copy: 'Duplicar pregunta',
    delete: 'Eliminar pregunta',
    add: 'Agregar nueva pregunta',
    advanced: 'Opciones avanzadas',
    type: 'Tipo',
    date: 'Fecha',
    dateAndTime: 'Fecha y Hora',
    time: 'Hora',
    alternativeColumns: 'Columnas alternativas',
    addOption: 'Agregar opción',
    dataListSource: 'Fuente lista datos',
    changeDataList: 'Cambiar lista de datos',
    listValues: 'Valores lista',
    addListValue: 'Agregar valor lista',
    deleteAlternative: 'Eliminar alternativa',
    required: 'Obligatorio',
    validateRutChile: 'RUT (Chile)',
    validateMail: 'Correo electrónico',
    validateContainer: 'ID de contenedor',
    validate: 'Validar',
    codeType: 'Cambiar tipo de código',
    disableGallery: 'Deshabilitar galería',
    maxImagesPerItem: 'Max. imágenes por ítem',
    moveUp: 'Mover hacia arriba',
    moveDown: 'Mover hacia abajo',
    alternative: 'Alternativa',
    snackBarShared: 'invitaciones enviadas',
    snackBar: 'Tu formulario se ha guardado correctamente!',
    newListData: 'Nueva Lista De Datos',
    section: 'Agregar Sección',
    sectionName: 'Sección sin nombre',
    duplicateSection: 'Duplicar sección',
    deleteSection: 'Eliminar sección',
    delSectionsQuestions: 'Eliminar seccion y preguntas',
    areYouSure: '¿Estás seguro/a?',
    cancel: 'Cancelar',
    sectionAlertOne:
      'Se eliminará la sección, pero las preguntas se sumarán a la sección anterior.',
    sectionAlertTwo: 'Se eliminará la sección y también las preguntas que contiene.',
    yesDelete: 'Sí, eliminar',
    questionAlert: 'Esta pregunta se eliminará de forma permanente',
    copyName: 'Copia',
    nameAlert: 'Las preguntas deben tener nombres distintos.',
    isRepeated: 'está repetido.',
    if: 'si',
    is: 'es',
    to: 'a',
    goTo: 'ir a',
    sectionNameTag: 'Nombre sección',
    skipQuestions: 'Saltar preguntas',
    equal: 'igual',
    dif: 'diferente',
    empty: 'vacía',
    notEmpty: 'no vacía',
    skipOnlyMobile: 'Solo aplica para formularios móviles',
    disableSkip: 'Este tipo de pregunta no está habilitada para saltos',
    next: 'siguiente pregunta',
    end: 'terminar formulario',
    payingAlert:
      'Esta funcionalidad no está disponible en el plan gratuito, debe activar su plan en la configuración / facturación.',
    showPercent: 'Mostrar porcentaje',
    showSamplingPercent: 'Mostrar porcentaje muestreo',
    showPrice: 'Mostrar precio',
    showAvg: 'Mostrar promedio',
    showFactor: 'Mostrar multiplicación',
    camQuality: 'Calidad de cámara',
    high: 'Alta',
    mid: 'Media',
    low: 'Baja',
    recommended: 'recomendado',
    photoAlert: 'la sincronización podría tardar más',
    sectionNameAlert:
      'No deberían existir secciones con el mismo nombre. Por favor renombra la sección: ',
    formatType: 'Tipos de formato',
    any: 'Cualquiera',
    howToCreate: 'Cómo crear formularios',
    good: 'Bueno',
    regular: 'Regular',
    bad: 'Malo',
    newOption: 'Nueva opción',
    moveSection: 'Mover sección',
    oldShareForm: 'Comparte este form con un enlace público',
    shareForm: 'Enlace Formulario',
    shareEmail: 'Comparte via email',
    shareWhatsapp: 'Comparte via WhatsApp',
    shareWhatsappMessage: 'Envía tu formulario junto con un mensaje a través de WhatsApp',
    shareWhatsappMessageApi: 'Hola, te invito a responder el siguiente formulario DataScope: ',
    formMadePublic: 'Se ha habilitado el link del formulario',
    undoFormPublic: 'El link del formulario se ha deshabilitado',
    shareForm2: 'Comparte este formulario',
    shareFormTip:
      'Cualquier persona, tanto usuarios registrados como no registrados, podrá completar este formulario web si comparte el enlace público con ellos. Sus respuestas quedarán registradas a nombre de un usuario genérico.',
    shareFormOn: 'Hacer este formulario público',
    shareFormOff: 'Remover acceso público',
    shareViaEmailTip: 'Puedes incluir más de un correo con enter, espacio o coma',
    quantity: 'Cantidad máxima de archivos',
    new: 'Nuevo!',
    warning: 'Temporalmente sólo disponible para formularios web',
    testYourForm1: 'Descarga nuestra App oficial en ',
    testYourForm2: 'Posteriormente puedes iniciar sesión con estas mismas credenciales.',
    testYourForm3: '¡Prueba tu formulario en nuestra app!',
    testYourForm4:
      '¡Escanea este código QR con la cámara de tu celular para visualizar tu formulario!',
    testYourForm5:
      '¡Escanea este código QR con la cámara de tu celular para descargar nuestra app y visualizar tu formulario!',
    copyLink: 'Copiar enlace',
    copiedLink: 'Copiado!',
    visitForm: 'Visitar',
    accessYourForm: 'Accede a tu formulario aquí',
    downloadApp: 'Descarga nuestra app',
    ok: 'ok',
    and: 'y',
    greater: 'Mayor que',
    greaterOrEqual: 'Mayor o igual que',
    less: 'Menor que',
    lessOrEqual: 'Menor o igual que',
    between: 'Entre',
    notBetween: 'No está entre',
    number: 'Número',
    noValidation: 'Sin validación',
    validNumberWarning: 'Ingresa un número válido',
    validSetWarning: 'Ingresa un rango válido',
    separator: 'Separador',
    separatorWarning: 'Ingresa un separador válido',
    relatedList: 'Lista relacionada',
    header: 'Encabezado',
    headerPlaceholder: 'País | Ciudad | Calle',
    headerWarning: 'Debes ingresar un encabezado que contenga al separador',
    headerDefault: 'Lista sin encabezado',
    deprecatedWarning: 'Este tipo de pregunta ya no es compatible',
    combineAlert1: 'Solo se pueden añadir preguntas al grupo de preguntas repetibles.',
    combineAlert2:
      'Una pregunta dentro de un grupo de preguntas repetibles no puede contener otro grupo de preguntas repetibles.',
    combineAlertTitle: 'Error',
    emptySubform: 'Vaciar',
    emptySubformAlert:
      'El grupo de preguntas repetibles no puede estar vacío, una pregunta ha sido creada automáticamente.',
    emptySubformAlertTitle: 'Cuidado',
    emptySubformTooltip: 'Eliminar todas las preguntas del grupo de preguntas repetibles',
    removeSubformQuestionTooltip: 'Eliminar pregunta del grupo de preguntas repetibles',
    subformRemoveConfirm:
      'Esta pregunta se eliminará de este grupo de preguntas repetibles y se agregará al formulario principal.',
    subformEmptyConfirm:
      'Todas las preguntas se eliminarán del grupo de preguntas repetibles y se agregarán al formulario principal.',
    confirm: 'Confirmar',
    subformLimit: 'Limitar repeticiones',
    subformWarning: 'Para más de 5 repeticiones, actualiza tu plan en configuración > facturación',
    answer: 'Responder',
    includeComments: 'Comentario',
    commentsFormLabel:
      'Selecciona las respuestas en las que deseas incluir un comentario obligatorio',
    textCommentBtnTooltip: 'Texto Requerido',
    imageCommentBtnTooltip: 'Imagen Requerida',
    imageCommentBtnTooltipDisabled: 'No puedes agregar más de 500 imágenes en total',
    share: 'Compartir',
    mobileTab: 'Celular',
    webTab: 'Computador',
    max: 'Máximo',
    min: 'Mínimo',
    asCount: 'Conteo',
    dataListSearchModalTitle: 'Mis listas',
    editLists: 'Editar listas',
    use: 'Usar',
    usedInThisForm: 'Usadas en este formulario',
    otherLists: 'Otras listas',
    multi_photo: 'Múltiples fotos',
    mobileOnlyAlert:
      'Disponible solo en la app. Si haces esta pregunta obligatoria, podrás responder el formulario desde web, pero la pregunta no aparecerá.',
    availableAppWeb: 'Disponible en web y app',
    imageDisabledByLimit:
      'La opción de imagen fue deshablitada. No es posible subir más de 500 imágenes en total',
    formBuilderBlockedAlertPart1:
      'No tienes permisos para editar este formulario. Si quieres crear o editar formularios ',
    formBuilderBlockedAlertPart2: 'actualiza tu plan',
    formBuilderBlockedAlertPart3: ' ahora y desbloquea la productividad sin límites',
    formNameExists: 'El nombre del formulario ya existe',
    saveError: 'Error al guardar el formulario',
  },
  help: {
    multi_option: 'Selecciona una alternativa en una lista de elementos',
    select_metadata: 'Selecciona una alternativa dentro de una lista de datos dinámica',
    select_option_metadata: 'Selecciona una alternativa para cada elemento de la lista',
    select_option_metadata_comments:
      'Selecciona alternativas fijas para cada elemento de una lista de datos dinámica',
    track_code: 'Permite asignar un código de seguimiento especifico para cada formulario',
    static_text: 'Permite agregar textos fijos como una instrucción del formulario',
    select_check_metadata: 'Selecciona múltiples elementos en una lista de datos dinámica',
    select_number_metadata: 'Selecciona un número para cada elemento de la lista de datos dinámica',
    select_text_metadata: 'Escribe un texto por cada elemento de una lista de datos dinámica',
    table: 'Crea un grupo de preguntas que puedes responder más de una vez',
  },
  types: {
    text: 'Texto',
    date: 'Fecha/Hora',
    number: 'Número',
    signature: 'Firma',
    barcode: 'Escáner',
    photo: 'Foto',
    multi_photo: 'Foto',
    multi_option: 'Selección Única',
    select_option_metadata: 'Checklist',
    select_option_metadata_comments: 'Checklist',
    select_check_metadata: 'Checkbox',
    select_number_metadata: 'Número Lista Datos',
    select_text_metadata: 'Texto Lista Datos',
    static_text: 'Instrucción',
    select_metadata: 'Selección Lista Datos',
    table: 'Grupo de Preguntas Repetibles',
    attachment: 'Adjuntar Archivo',
    deprecated: 'Deprecada',
  },
  links: {
    appStore: 'https://itunes.apple.com/us/app/datascope-forms/id1132697668?l=es&ls=1&mt=8',
    playStore: 'https://app.datascope.io/apk',
    appLink: 'https://datascope.page.link/3N7P',
  },
  listManager: {
    dataListSource: 'Fuente de datos',
    name: 'Nombre',
    description: 'Descripción',
    code: 'Código',
    listItems: 'Elementos de la lista',
    emptyList: 'Esta lista no tiene elementos',
    attribute1: 'Atributo 1',
    attribute2: 'Atributo 2',
    listSubtitle:
      'Todas estas listas van a estar disponibles al momento de crear un nuevo formulario móvil',
    seeAll: 'Ver todas las listas',
    seeObj: 'Ver elementos de la lista',
    edit: 'Editar lista',
    editObj: 'Editar elemento',
    delete: 'Eliminar lista',
    deleteObj: 'Eliminar elemento',
    deleteObjectWarning: '¿Estás seguro/a de que quieres eliminar este elemento de la lista?',
    deleteYes: 'Sí, eliminar',
    saveChanges: 'Guardar',
    cancel: 'Cancelar',
    itemsOf: 'Elementos de:',
    addNewItem: '+ Nuevo elemento',
    page: 'Página ',
    of: ' de ',
    close: 'Cerrar',
    legacy: 'Ir al manejador de listas completo',
    locations: 'Lugares',
  },
  tasksForms: {
    templates: 'Plantillas de formularios',
    useTemplate: 'Usar plantilla',
    newVersion: 'Cambiar a nueva versión',
    oldVersion: 'Cambiar a versión antigua',
    uniq: 'El nombre de la pregunta debe ser único',
    title: 'Formularios',
    preview: 'Vista previa',
    export: 'Exportar',
    export_help: 'Puedes copiar este código fuente y pegarlo en otro formulario',
    import: 'Importar',
    import_confirm: '¿Estás seguro de que quieres importar este formulario?',
    import_success: 'Se está importando su formulario, puede tardar algunos minutos',
    start_testing: '¡Es momento de probar tu formulario nuevo!',
    test_button: 'Probar formulario',
    start_testing_desc:
      'Felicitaciones, ya tienes tu primer formulario. Ahora puedes probarlo y mandar tu primera respuesta desde la app móvil.',
    import_error:
      'No se pudo importar el formulario. Compruebe si es un formulario válido o si ya está importado.',
    clone: 'Duplicar',
    default_title: 'Mi Formulario',
    default_question: 'Pregunta',
    drag_questions:
      'Por el momento no tienes preguntas en el formulario, arrastra tus preguntas acá',
    new: 'Nuevo formulario',
    new_ai: 'Formulario con IA',
    google_forms: 'Importar Google Form',
    are_you_sure: '¿Estas seguro?',
    status: 'Estado',
    active: 'Activo',
    inactive: 'Inactivo',
    publish: 'Activar',
    unpublish: 'Desactivar',
    last_info: 'Agregar HTML al final del PDF',
    current_logo: 'Logo del Formulario',
    form_logo: 'Logo Formulario',
    form_name: 'Nombre',
    actions: 'Configurar',
    answer_form: 'Responder',
    edit_app: 'Configurar App',
    edit_pdf: 'Configurar PDF',
    edit_email: 'Configurar Email',
    edit_share: 'Compartir',
    error_form: 'Ha ocurrido un error',
    success_form: 'Éxito',
    delete: 'Eliminar',
    date: 'Fecha/Hora',
    date_type: 'Tipo Fecha',
    date_type_date: 'Fecha',
    date_type_datetime: 'Fecha y Hora',
    date_type_time: 'Hora',
    text: 'Texto',
    number: 'Número',
    multi_option: 'Selección Única',
    signature: 'Firma',
    barcode: 'Escáner',
    photo: 'Foto',
    select_metadata: 'Selección Lista Datos',
    select_option_metadata: 'Checklist',
    track_code: 'Código de seguimiento',
    static_text: 'Texto Fijo',
    select_check_metadata: 'Checkbox',
    select_number_metadata: 'Número Lista Datos',
    select_text_metadata: 'Texto Lista Datos',
    table: 'Grupo de Preguntas Repetibles',
    name: 'Título Pregunta',
    description: 'Descripción Pregunta',
    label: 'Nombre Sección',
    choices: 'Alternativas',
    choice: 'Alternativa',
    add_choice: 'Agregar Opción',
    add_list_value: 'Agregar Valor Lista',
    add_question: 'Agregar Pregunta',
    list_values: 'Valores Lista',
    delete_choice: 'Eliminar Opción',
    drop_question: 'Arrastrar preguntas repetibles aquí',
    metadata_type: 'Fuente Lista Datos',
    create_metadata: 'Nueva Lista de Datos',
    change_metadata: 'Cambiar Lista de Datos',
    save_form: 'Guardar Formulario',
    version: 'versión',
    no_name: 'Sin Nombre',
    no_questions: 'Debe tener al menos una pregunta',
    no_data: 'No hay formularios disponibles',
    blank_table: 'Sub Formulario debe tener al menos una pregunta',
    sub_questions: 'Sub Preguntas',
    disable_gallery: 'Deshabilitar Galería',
    required: 'Obligatorio',
    back: 'Atrás',
    location: 'Lista de Lugares',
    help_title: '¿Necesitas Ayuda para crear formulario?',
    help_instruction: 'Solicitar creación de formulario',
    help_description:
      '"***Por favor explicar la información que deseas levantar y cada pregunta del formulario. Adjunta una foto o documento de este mismo"',
    example_title_1: 'Construya sus propios formularios digitales',
    example_description_1:
      'Con el Form Builder puede crear formularios perfectos. Es fácil, intuitivo y potente.',
    success_created: 'El formulario se creó correctamente.',
    hide_logic: 'Saltar preguntas',
    show_price: 'Mostrar Precio',
    show_avg: 'Mostrar Promedio',
    show_factor: 'Mostrar Multiplicación',
    show_percent: 'Mostrar Porcentaje',
    equal: 'Igual',
    not_equal: 'Diferente',
    if: 'SI',
    is: 'ES',
    to: 'A',
    go_to: 'IR A',
    next_question: 'Siguiente Pregunta',
    finish_form: 'Terminar Formulario',
    standard_pdf: 'PDF Estándar',
    customized_pdf: 'PDF Personalizado',
    open_editor: 'Abrir Editor PDF',
    own_pdf: 'Cree su propio diseño PDF',
    need_your_pdf: '¿Necesita su propio diseño PDF?',
    pdf_description:
      'El creador de PDF más flexible para crear sus propios documentos con los datos recopilados',
    folders: 'Carpetas',
    manage_folders: 'Nueva carpeta',
    manage_states: 'Ver estados',
    view_all_folders: 'Ver todas las carpetas',
    searchSend: 'Buscar',
    searchForm: 'Buscar formularios...',
    custom_pdf: {
      step1: 'Crear Plantilla',
      step1_desc:
        'Debes crear una plantilla usando el editor de PDF. Puedes utilizar todos los valores de respuestas del formulario. Se utilizaran los datos del último formulario a modo de ejemplo.',
      step2: 'Activar Plantilla',
      step2_desc:
        'Una vez que la plantilla este lista, puedes activarla. Una vez activado todas las exportaciones se generarán con el formato especificado previamente.',
    },
    help: {
      multi_option: 'Selecciona una alternativa en una lista de elementos',
      select_metadata:
        'Selecciona una alternativa dentro de una lista de datos dinámica ( Ej. Productos, Clientes, etc..)',
      select_option_metadata:
        'Selecciona alternativas fijas para cada elemento de una lista de datos dinámica',
      track_code: 'Permite asignar un código de seguimiento especifico para cada formulario',
      static_text: 'Permite agregar texto fijo como instrucción del formulario',
      select_check_metadata:
        'Permite hacer un check de múltiples elementos dentro de una lista de datos dinámica',
      select_number_metadata:
        'Selecciona un número para cada elemento de una lista de datos dinámica',
      select_text_metadata: 'Selecciona texto para cada elemento de una lista de datos dinámica',
      table: 'Crear un grupo de preguntas que se pueden responder más de una vez',
      description:
        '"Texto más extenso para mostrar en la App. No aparece al exportar datos. (Opcional)"',
      title: 'Título corto para identificar pregunta. Aparece al exportar datos.',
    },
    share: {
      title: 'Generar Link Google Forms',
      link: 'Link:',
      edit_link: 'Editar Google Form',
      export: 'Exportar Google Form',
      sendgrid_template_id: 'd-e6004a145c78496a8d02f26600dec5e0',
      inactive_public_form: 'Este formulario ya no es accesible usando este link.',
      success_message: 'Hemos recibido tu respuesta correctamente',
      make_form_private: 'Eliminar acceso público al formulario',
      make_form_private_notice:
        'Este formulario ahora es privado. Los enlaces públicos previamente compartidos han dejado de ser válidos.',
      make_form_public: 'Hacer público este formulario',
      make_form_public_notice:
        'Este formulario ahora es público. ¡Ahora puede compartirlo con personas ajenas a su organización!',
      share_via_email: 'Comparte este formulario mediante un email',
      share_email_tooltip:
        'Se enviará un enlace público, accesible a cualquier persona, a aquellos correos electrónicos que no estén asociados a esta cuenta. Se enviará un enlace de uso privado a aquellos correos electrónicos que pertenezcan a usuarios registrados de esta cuenta.',
      share_whatsapp_message: 'Hola, te invito a responder el siguiente formulario DataScope: ',
      share_public_link: 'Comparte este formulario mediante un link público',
      share_public_link_tooltip:
        'Cualquier persona, tanto usuarios registrados como no registrados, podrá completar este formulario si comparte el enlace público con ellos. Sus respuestas quedarán registradas a nombre de un usuario genérico, webforms_user_',
      share: 'Compartir',
      copy: 'Copiar',
      trigger: 'Trigger no instalado. Límite de 20 Alcanzado.',
      loading: 'Cargando Formulario...',
      failed: 'Exportación de Formulario Fallida.',
      auth: "'TIP: 'Si tiene problemas, intente cerrar las sesiones de Google (por ejemplo, en google.com), y luego ingrese sólo con la cuenta a la que quiere exportar el formulario.'",
      add_all_permissions: 'Agregar este formulario a todos los usuarios',
      permission_success:
        'Permiso agregado para todos los usuarios, debe cerrar la sesión y volver a iniciar en la aplicación para actualizar los formularios',
    },
    delete_form_confirm: '¿Estás seguro que quieres eliminar este formulario?',
    delete_form_warning: 'Una vez que lo elimines, no podrás recuperarlo.',
    delete_form_success: 'Formulario eliminado con éxito',
    delete_form_failure: 'Error al eliminar el formulario',
    clone_form_success: 'Formulario clonado con éxito',
    clone_form_failure: 'Error al clonar el formulario',
    publish_form_success: 'Formulario activado con éxito',
    publish_form_failure: 'Error al activar el formulario',
    unpublish_form_success: 'Formulario desactivado con éxito',
    unpublish_form_failure: 'Error al desactivar el formulario',
    rename: 'Renombrar',
    rename_folder: 'Renombrar carpeta',
    folder_name: 'Nombre de la carpeta',
    new_name: 'Nuevo nombre',
    update: 'Actualizar',
    cancel: 'Cancelar',
    rename_success: 'Carpeta renombrada con éxito',
    rename_failure: 'Error al renombrar la carpeta',
    delete_folder_confirm: '¿Estás seguro que quieres eliminar la carpeta ',
    delete_warning:
      'Una vez que la elimines, estos formularios pasarán a la sección de formularios (sin carpeta).',
    delete_folder_success: 'Carpeta eliminada con éxito',
    delete_folder_failure: 'Error al eliminar la carpeta',
    move_to_folder: 'Mover a carpeta',
    remove_from_folder: 'Remover de carpeta',
    move: 'Mover',
    warn_empty_folder: 'Esta acción eliminará la carpeta al quedar esta vacía.',
    move_form_success: 'Formulario movido exitosamente',
    move_forms_success: 'Formularios movidos exitosamente',
    move_form_failure: 'Error al intentar mover el formulario',
    move_forms_failure: 'Error al intentar mover los formularios',
    create: 'Crear',
    create_form_folder: 'Crear carpeta de formularios',
    create_folder_success: 'Carpeta creada con éxito',
    create_folder_failure: 'Error al crear la carpeta',
    how_to_create_folder: '¿Cómo quieres crear tu carpeta?',
    next: 'Siguiente',
    form_selection: 'Selección formularios',
    no_forms_available: 'No tienes formularios disponibles en este momento',
    forms: 'Formularios',
    form: 'formulario',
    bulk_action: {
      publish: {
        confirm: '¿Estas seguro que quieres activar',
        info: 'Una vez los actives, los podrás volver a desactivar.',
        success: 'Formularios activados con éxito',
        failure: 'Error al activar los formularios',
      },
      unpublish: {
        confirm: '¿Estas seguro que quiere desactivar',
        info: 'Una vez los desactives, los podrás volver a activar.',
        success: 'Formularios desactivados con éxito',
        failure: 'Error al desactivar los formularios',
      },
      delete: {
        confirm: '¿Estas seguro que quieres eliminar',
        info: 'Una vez los elimines, no los podrás volver a recuperar.',
        success: 'Formularios eliminados con éxito',
        failure: 'Error al intentar eliminar los formularios',
      },
    },
    activate_signature: 'Activar firma electrónica',
    activate_signature_success: 'Firma electrónica activada con éxito',
    activate_signature_error: 'Error al activar la firma electrónica',
    deactivate_signature: 'Desactivar firma electrónica',
    deactivate_signature_success: 'Firma electrónica desactivada con éxito',
    deactivate_signature_error: 'Error al desactivar la firma electrónica',
    formResults: 'Resultados: ',
    formResultsOf: 'de',
    current_folder: 'Carpeta actual:',
    formLimitReachedTooltip: 'Alcanzaste el límite de formularios permitidos. ',
    upgradePlanTooltipLink: 'Actualiza tu plan',
    upgradePlanTooltip: ' para crear formularios',
    importPermissionError: 'No tienes permisos para importar plantillas de formularios.',
    projectLimitReachedError:
      'No se pudo importar el formulario. Has alcanzado el número máximo de formularios permitidos.',
  },
  mobileUsers: {
    users: 'Usuarios',
    new: 'Crear Usuario',
    import: 'Importar',
    import_users: 'Importar Usuarios',
    how_import: '¿Cómo importar usuarios?',
    bulk_actions: 'Acciones Masivas',
    add_users_to_group: 'Agregar usuarios a grupo',
    deactivate_users: 'Deshabilitar usuarios',
    add: 'Agregar',
    add_to_group: 'Agregar a grupo',
    selectAGroup: 'Selecciona un grupo',
    duplicate_user:
      'Ya existe un usuario eliminado con el mismo correo electrónico. Por favor, reactive ese usuario en su lugar',
    identifier_help: 'Necesario para iniciar sesión en el dispositivo móvil',
    advanced: 'Configuración Avanzada',
    old_password_required:
      'no es válido. Para cambiar la contraseña de administrador se requiere confirmación.',
    group_users: 'Grupos de Usuarios',
    group_new: 'Crear Grupo',
    you: 'usted',
    location_perm: 'Permisos a lugares',
    forms_perm: 'Permisos a formularios',
    activated: 'Activos',
    deactivated: 'Desactivados',
    modifications_history: 'Historial',
    noGroups: 'No tienes grupos de usuarios',
    infoNotice:
      'Los usuarios solo podrán ver los formularios y respuestas que les correspondan según su grupo.',
    delete_group: 'Eliminar grupo',
    cancel: 'Cancelar',
    delete: 'Eliminar',
    rename: 'Renombrar',
    rename_group: 'Renombrar grupo',
    group_name: 'Nombre del grupo',
    new_name: 'Nombre nuevo',
    update: 'Actualizar',
    confirm_group_delete: '¿Estás seguro que quieres eliminar el grupo ',
    confirm_group_delete2:
      'Una vez lo elimines, los usuarios que no estén en otros grupos quedarán sin grupo y tendrán los permisos individuales previamente asignados.',
    update_groups_success: 'Cambios realizados con éxito',
    update_groups_error: 'Ocurrió un error al guardar los cambios',
    userInfo: 'Información del usuario',
    userGroupsHelp: 'Este usuario pertenece a los siguientes grupos:',
    manageGroupModalTitle: 'Permisos en',
    searchForm: 'Buscar formulario...',
    searchUser: 'Buscar usuario...',
    groupUpdateSuccess: 'Grupo actualizado exitosamente',
    permissions_title: 'Permisos',
    forms_title: 'Formularios',
    group_name_exists: 'Un grupo con ese nombre ya existe',
    edit_group_permissions: 'Permisos formularios',
    edit_groups: 'Agregar a grupo',
    save: 'Guardar',
    editGroupsInfo:
      'Los usuarios asignados a uno o varios grupos, obtendrán permisos para acceder a los formularios de esos grupos. En consecuencia, no podrán tener formularios asignados de manera individual al mismo tiempo.',
    modifiedGroup: 'Grupo modificado',
    modificationAuthor: 'Modificado por',
    modificationDate: 'Fecha',
    modification: 'Modificación',
    added: 'Agregados',
    removed: 'Eliminados',
    group: 'Grupo',
    createdGroup: 'Grupo creado',
    deletedGroup: 'Grupo eliminado',
    renamedGroup: 'Grupo renombrado',
    emptyHistory: 'No hay historial de modificaciones',
    previousName: 'Nombre anterior',
    removeFromGroup: 'Eliminar del grupo',
    remove: 'Remover',
    removeUsersConfirm: '¿Estás seguro que quieres remover',
    removeUsersConfirm2: 'usuario(s) del grupo',
    onceUsersRemoved:
      'Una vez los remuevas, estos pasarán a usuarios sin grupo o quedarán en los otros grupos a los que pertenecen.',
    noUsersInGroup: 'Aún no has agregado usuarios a este grupo',
    searchGroups: 'Buscar grupos...',
    pendingConfirmationTitle: 'Confirmación pendiente',
    pendingConfirmationSubtitle: 'Comparte este enlace solo con este usuario',
    copy: 'Copiar',
    linkCopied: 'Enlace copiado!',
    resendInvitation: 'Reenviar',
    deleteGroupPendingSignatureError: 'Grupo contiene usuarios con firmas pendientes',
    invite_user: {
      title: 'Invitar Usuarios',
      placeholder: 'ejemplo@ejemplo.com',
      help: 'Si quieres cambiar los permisos, da clic en configurar permisos',
      btn: 'Invitar',
      config: 'Configurar Permisos',
      successSingular: 'Invitación enviada exitosamente',
      successPlural: 'Invitaciones enviadas exitosamente',
      error:
        'No se pudo invitar a los usuarios, verifique que los correos electrónicos sean válidos y no se hayan utilizado antes',
      emailFormatError: 'Formato de correo incorrecto, por favor utilice ejemplo@ejemplo.com',
      emails: 'Emails',
    },
    permissions: {
      answers: 'Ver respuestas de formularios',
      evaluate: 'Cambiar Estado Respuestas Formularios',
      chat: 'Chatear',
      tasks: 'Responder Formularios',
      assigned: 'Ver Formularios Asignados',
      users: 'Mostrar y crear nuevos usuarios',
      export: 'Exportar Datos',
      metadata: 'Crear Listas Personalizadas',
      modify: 'Modificar Respuestas de Formularios',
      edit_forms: 'Editar Formularios',
      evaluate_delete: 'Eliminar/Recuperar Respuestas Formularios',
    },
    roles: {
      customized: 'Customizado',
      administrator: 'Administrador',
      superadmin: 'Administrador Principal',
      supervisor: 'Supervisor',
      editing_operator: 'Operario editor',
      operator: 'Operario',
    },
    table: {
      name: 'Nombre',
      user: 'Usuario',
      role: 'Rol',
      lastConnection: 'Última conexión',
      noLastConnection: 'Última conexión no disponible',
      actions: 'Acciones',
      configure: 'Configurar',
      edit: 'Editar',
      deactivate: 'Deshabilitar',
      activate: 'Habilitar',
      no_data: 'No hay usuarios disponibles',
      groups: 'Grupos',
      viewMore: 'Ver más',
      noForms: 'Este grupo de usuarios no tiene formularios asignados',
      not_confirmed: 'Confirmación Pendiente',
    },
    logs: {
      user: 'Usuario modificado',
      author: 'Autor de la modificación',
      date: 'Fecha',
      modification: 'Modificación',
      activated: 'Reactivado',
      deactivated: 'Eliminado',
    },
    deactivation: {
      cancel: 'Cancelar',
      confirmationTitle: '¿Estás seguro que quieres deshabilitar este usuario?',
      confirmationMessage:
        'Los datos no se perderán, luego podrás volverlo a activar en usuarios inactivos.',
      bulkConfirmationTitle: '¿Estás seguro que quieres deshabilitar',
      bulkConfirmationMessage:
        'Los datos no se perderán, luego podrás volverlos a activar en usuarios inactivos.',
      success: 'Usuario(s) desactivado(s) exitosamente',
      error: 'Ocurrió un error al desactivar los usuario(s)',
    },
    createUser: {
      information: {
        title: 'Información',
        name: 'Nombre',
        lastName: 'Apellido',
        email: 'Email',
        example: 'ejemplo@ejemplo.com',
        password: 'Contraseña',
        passwordHelper: 'La contraseña debe tener al menos 6 caracteres',
        phone: 'Teléfono',
      },
      group: {
        title: 'Grupo',
        selectHelper: 'Selecciona un grupo existente (Puedes seleccionar varios)',
        noGroup: 'Sin grupo',
        newGroup: 'Nuevo grupo',
        newGroupHelper: 'Escribe el nombre del grupo',
        newGroupSuccess: 'Nuevo grupo creado con éxito!',
        newGroupError: 'Ocurrió un error creando el nuevo grupo',
        configuration: 'Debes configurar permisos a formularios para este grupo',
        formsHelp: 'Formularios a los que tendrá acceso este usuario:',
      },
      forms: {
        title: 'Formularios',
        allForms: 'Formularios',
        addForm: 'Agregar formulario',
        formsHelper: 'Podrás ver los formularios y las respuestas de los seleccionados.',
        formsHelperDisabled: 'Podrás ver estos formularios y sus respuestas.',
        formsGroupHelper:
          'Los formularios y lugares a los que tendrá acceso este usuario son los pertenecientes a el/los grupos previamente elegidos.',
        seeMore: 'ver más',
        groupsAlert:
          'No puedes agregar formularios porque asignaste o creaste un grupo previamente',
        back: 'Atras',
        folders: 'Carpetas',
        close: 'Cerrar',
        formHasPendingSignature:
          'Este usuario tiene firmas pendientes. Mientras no se respondan o eliminen no podrás modificar los permisos.',
        massSelectionBlockedByPendingSignature:
          'Selección masiva de formularios bloqueada por firmas pendientes.',
        foldersBlockedByPendingSignature:
          'Selección de carpetas bloqueada por firmas pendientes. Abre la carpeta para seleccionar formularios.',
      },
      permissions: {
        title: 'Permisos',
        editingMyself:
          'No puedes cambiar tus propios permisos. Pide a otra persona que lo haga si deseas modificarlos.',
        basic: {
          title: 'Básico',
          viewFormAnswers: 'Ver respuestas de formularios',
          chat: 'Chatear',
          submitFormAnswers: 'Responder formularios',
          viewAssignedForms: 'Ver formularios asignados',
        },
        advanced: {
          title: 'Avanzado',
          changeFormStatus: 'Cambiar estado de formularios',
          createMetadata: 'Crear listas personalizadas',
          editFormAnswers: 'Modificar respuestas de formularios',
          editForms: 'Editar formularios',
          evaluateDelete: 'Eliminar y recuperar respuestas de formularios',
        },
        admin: {
          title: 'Administración',
          viewAndCreateUsers: 'Mostrar y crear nuevos usuarios',
          exportData: 'Exportar datos',
        },
        editFormsTooltip1: 'Este usuario no tiene permiso para editar formularios. ¡',
        editFormsTooltipLink: 'Actualiza tu plan',
        editFormsTooltip2: ' ahora y desbloquea la productividad sin límites!',
      },
      locations: {
        title: 'Lugares (Opcional)',
        helper:
          'Si activas esta opción, el usuario sólo podrá ver los formularios asociados a los lugares que selecciones',
        locations: 'Lugares',
        andOthers: ', y otros...',
      },
      signature: {
        name: 'Nombre completo',
        companyName: 'Nombre sociedad',
        userIdNumber: {
          default: 'Número de identificación',
          CL: 'RUT',
          PE: 'DNI',
          MX: 'CURP',
        },
        country: 'País',
        selectCountry: 'Selecciona un país',
        userEmail: 'Email personal',
        legalRepresentative: 'Representante legal',
        legalRepresentativeIdNumber: {
          default: 'Número de identificacion tributaria representante legal',
          CL: 'RUT representante legal',
          PE: 'RUC representante legal',
          MX: 'RFC representante legal',
        },
        companyIdNumber: {
          default: 'Número de identificacion tributaria',
          CL: 'RUT empresa',
          PE: 'RUC empresa',
          MX: 'RFC empresa',
        },
        address: 'Dirección',
        companyEmailError: 'Debes usar un correo electrónico diferente',
        idNumberError: {
          default: 'Número de identificación tributaria inválido',
          CL: 'RUT inválido',
          PE: 'RUC inválido',
          MX: 'RFC inválido',
        },
        idNumberSuccess: {
          default: 'Tu número de identificación tributaria ha sido válidado',
          CL: 'Tu RUT ha sido verificado',
          PE: 'Tu RUC ha sido verificado',
          MX: 'Tu RFC ha sido verificado',
        },
      },
      cancel: 'Cancelar',
      save: 'Guardar',
      success: 'Nuevo usuario creado con éxito!',
      error: 'Ocurrió un error creando el usuario',
    },
    editUser: {
      information: {
        oldPassword: 'Contraseña actual',
        newPassword: 'Contraseña nueva',
        passwordChange: 'Cambiar contraseña',
        error: 'Ocurrió un error actualizando el usuario',
        success: 'Usuario actualizado con éxito!',
      },
    },
    createGroup: {
      button: 'Crear grupo',
      title: 'Crear grupo de usuarios',
      chooseHeader: '¿Cómo quieres crear el grupo?',
      name: 'Nombre grupo',
      nameHelper: 'Nombre del grupo',
      userSelection: 'Selección usuarios',
      formSelection: 'Selección formularios',
      importExcelNote: '*Luego tendrás que asignarle permisos a formularios',
      warning:
        'Los usuarios asignados a un grupo accederán a los formularios de ese grupo y no podrán tener formularios asignados de manera individual al mismo tiempo.',
    },
    setProfile: {
      completeProfile: 'Completa tu perfil',
      completeProfileDescription:
        'Por favor, ingresa los siguientes datos para ingresar a DataScope',
      newPassword: 'Nueva contraseña',
      name: 'Nombre',
      lastName: 'Apellido',
      phone: 'Teléfono',
      save: 'Guardar',
      updateSuccess: 'Perfil actualizado con éxito',
      updateError: 'Ocurrió un error al actualizar el perfil',
      identifier: 'Identificador',
    },
  },
  login: {
    greeting: '¡Hola de nuevo!',
    loginHelper: 'Para continuar, ingresa tus credenciales',
    googleLogin: 'Inicia sesión con Google',
    linkedinLogin: 'Inicia sesión con Linkedin',
    email: 'Correo electrónico',
    password: 'Clave',
    rememberMe: 'Recordarme',
    forgotPassword: '¿Olvidaste tu contraseña?',
    login: 'Iniciar sesión',
    noAccount: '¿No tienes una cuenta?',
    signUp: 'Regístrate aquí',
    or: 'ó',
  },
  templates: {
    total_templates: 'Total plantillas: ',
    see_all: 'Ver todas',
    showing_results: 'Mostrando resultados de',
    categories: 'Categorías',
    in: 'en',
    useTemplate: 'Usar plantilla',
    noResults: '¡No hemos encontrado lo que buscas, pero nos encantaría encontrar la solución!',
    contactSupport: 'Cuéntanos qué formulario necesitas via chat',
    next: 'Siguiente',
    back: 'Atrás',
    useCase: 'Puede ser usado para:',
    createdBy: 'Creado por',
    anonymousUser: 'Usuario anónimo',
    preview: 'Vista previa',
    editableTemplate: 'Esta plantilla es editable una vez que se importa.',
    allTemplates: 'Plantillas',
    noDescription: 'Sin descripción',
    bannerSlogan: 'Transforma tus operaciones con tecnología móvil',
    templateView: 'Vista plantilla',
    seeLess: 'Ver menos',
    seeMore: 'Ver más',
  },
  navbar: {
    features: 'Funcionalidades',
    solutions: 'Soluciones',
    work_orders: 'Órdenes de Trabajo',
    safety: 'Seguridad y Salud en el Trabajo',
    inspections: 'Inspecciones',
    quality: 'Controles de Calidad',
    resources: 'Recursos',
    blog: 'Blog',
    ds_academy: 'Academia DataScope',
    digitize: 'Sube tu formulario',
    roi: 'Calculadora de Ahorro',
    pricing: 'Precios',
    sales: 'Contáctanos',
    wapp: 'Hablemos por WhatsApp',
    call: 'Agenda un llamado',
    demo: 'Solicita una demo',
    sign_in: 'Iniciar Sesión',
    sign_up: 'Prueba Gratis',
    log_out: 'Cerrar Sesión',
    paths: {
      work_orders: 'ordenes-trabajo',
      safety: 'seguridad',
      inspections: 'inspecciones',
      quality: 'calidad',
      blog: 'blog',
      ds_academy: 'como-usar-datascope',
      digitize: 'sube-tu-formulario',
      roi: 'roi-chile',
    },
  },
  uiComponents: {
    search: 'Buscar',
    showingResultsFor: 'Mostrando resultados de búsqueda para',
    found: 'encontrados',
    cancel: 'Cancelar',
    selected: 'seleccionados',
    confirm: 'Confirmar',
    close: 'Cerrar',
    chooseMethodDialog: {
      fromDatascope: 'Desde DataScope',
      importExcel: 'Importar Excel',
    },
    signature: {
      title: 'Firma electrónica',
      alert:
        'Completa la siguiente información para activar la firma electrónica. El nombre y apellido en el área de información son necesarios para crear tu firma.',
      accountAlert: ' Recuerda hacer clic en "Guardar firma".',
      saveSignature: 'Guardar firma',
      completeSignatureWarning:
        'Debes completar todos los campos obligatorios para poder guardar tu firma',
      signatureRequiredFieldError: 'Este campo es obligatorio para crear la firma electrónica',
      updateSuccess: 'Firma actualizada con éxito',
      updateError: 'Ocurrió un error al actualizar la firma',
      invalidEmailError: 'Correo electrónico inválido',
      emailExistsError: 'El correo electrónico se encuentra en uso',
      validatingRut: 'Validando RUT...',
      emailErrors: {
        REGEX_INVALID: 'Correo electrónico inválido',
        IN_USE: 'El correo electrónico se encuentra en uso',
      },
    },
  },
  accountConfig: {
    information: {
      title: 'Configuración de cuenta',
      name: 'Nombre empresa',
      description: 'Descripción empresa',
      email: 'Email empresa',
      phone: 'Teléfono empresa',
      address: 'Dirección empresa',
      timezone: 'Zona horaria',
      locale: 'Idioma',
      logo: 'Logo',
      uploadLogo: 'Imagen',
      logoDescription:
        'La imagen debe estar en formato .PNG o .JPG y el tamaño debe ser 400x400 px con un peso no mayor a 2MB.',
      logoSizeError: 'El máximo tamaño de imagen permitido es de 2MB',
      saveSuccess: 'Configuración actualizada con éxito',
      saveError: 'Ocurrió un error al actualizar la configuración',
      pdfAlert: 'La información aquí configurada aparecerá en el encabezado del PDF estándar.',
      confirmDeleteLogo: '¿Estás seguro que quieres eliminar el logo?',
    },
    advancedConfig: {
      title: 'Configuración avanzada',
      activateSupportUser: 'Otorgar acceso al usuario de soporte DataScope',
      securityConfig: 'Configuración de seguridad',
      deactivateAccount: 'Deshabilitar cuenta',
      confirmTitle: '¿Estás seguro que deseas desactivar tu cuenta?',
      confirmMessage:
        'Esta acción es irreversible. También se desactivarán todos los usuarios y datos asociados a esta cuenta.',
    },
  },
  notifications: {
    title: 'Notificaciones',
    searchPlaceholder: 'Buscar por nombre formulario...',
    viewFormAnswer: 'Ver respuesta',
    viewExcel: 'Ver Excel',
    viewPdf: 'Ver PDF',
    tableHeaders: {
      date: 'Fecha',
      type: 'Tipo',
      form: 'Formulario',
      formCode: 'Código',
      user: 'Usuario',
      location: 'Lugar',
      actions: 'Acciones',
    },
    formExport: 'Exportación de formulario',
    formAnswer: 'Respuesta de formulario',
    filters: {
      title: 'Filtros',
      filterByType: 'Por tipo',
      filterByLocation: 'Por lugar',
      filterByUser: 'Por usuario',
      filterByDateRange: 'Por rango de fecha',
      startDate: 'Inicio',
      endDate: 'Fin',
      invalidDate: 'Fecha inválida',
      invalidDateRange: 'Rango de fechas inválido',
      to: 'hasta',
      eraseAll: 'Borrar todo',
      apply: 'Aplicar',
      formAnswers: 'Respuestas',
      formExports: 'Exportaciones',
      clear: 'Borrar',
      locationPlaceholder: 'Selecciona un lugar',
      userPlaceholder: 'Selecciona un usuario',
      showingResultsFor: 'Mostrando resultados de búsqueda para',
    },
    noNotifications: 'No tienes notificaciones en este momento',
    noResults: 'No se encontraron notificaciones relacionadas con los filtros aplicados',
    limitReached:
      'Su cuenta alcanzó el límite, debes activar la cuenta para habilitar nuevamente las funcionalidades.',
  },
  formEmailBuilder: {
    message: 'Mensaje',
    subject: 'Asunto',
    senderName: 'Nombre remitente del correo',
    addFooter: 'Agregar Firma',
    footerTitle: 'Firma',
    notification: 'Notificación',
    autoNotify: 'Notificar a los correos fijos',
    save: 'Guardar',
    cancel: 'Cancelar',
    linkName: 'Link descarga PDF formulario',
    linkNamePlaceholder: 'Escribe el nombre que quieres que tenga link',
    linkNameHelp: 'El nombre que configures será el del link de descarga que llegará al correo.',
    saveSuccess: 'Configuraciones guardadas con éxitos',
    saveError: 'Ocurrió un error al guardar las configuraciones',
    autoGeneratePdf: 'Generar PDF automáticamente',
  },
  formSignatureBuilder: {
    signatureTitle: 'Activación Firma',
    checkboxSignatureActivate: 'Activar firma electrónica',
    settingsTitle: 'Configuración general',
    save: 'Guardar',
    cancel: 'Cancelar',
    saveSuccess: 'Configuración guardada con éxito',
    saveError: 'Ocurrió un error al guardar la configuración',
    saveErrorReload:
      'Ocurrió un error al guardar la configuración. Por favor, recargue la página e intente nuevamente',
    accountSignatureConfigError: 'Falta configurar la firma electrónica de la cuenta',
    generalSettings: 'Configuración general',
    mandatorySignature:
      'Firma obligatoria (Todos los usuarios configurados deben responder a la solicitud, ya sea firmando o rechazándola)',
    optionalSignature: 'Firma opcional (Uno de los firmantes configurados debe firmar)',
    signersSettings: 'Configuración firmantes',
    addUsers: 'Agregar usuarios',
    authorMustSign: 'El autor del formulario también debe firmar',
    assignUsers: 'Asignar usuarios',
    assignOwner: 'Asignar responsable',
    searchPlaceholder: 'Buscar usuario o grupo',
    usersTab: 'Usuarios',
    groupsTab: 'Grupos',
    addButton: 'Agregar',
    externalUsers: 'Usuarios externos',
    externalUsersDescription: 'Los usuarios externos podrán ver y firmar el formulario',
    selectResponsible: 'Selecciona el responsable de la solicitud',
    addResponsible: 'Agregar responsable',
    selectUsersToSign: 'Selecciona los usuarios que deben firmar el documento',
    selectResponsibleTooltip:
      'El usuario seleccionado será responsable de todas las firmas y podrá editar la solicitud',
  },
  pricing: {
    title: '¡Descubre el plan perfecto para ti!',
    basic: {
      title: 'Básico',
      subtitle: 'Perfecto para explorar las funcionalidades de DataScope',
      features: [
        'Usuarios ilimitados',
        '60 respuestas de formulario/mes',
        'Datos almacenados por 30 días',
        'Constructor de formularios básico',
        'Asignador de tareas',
        'Dashboard estándar',
        'Resolución de fotografías estándar',
        'Funcionamiento sin internet',
      ],
      buttonText: 'Plan actual',
    },
    standard: {
      title: 'Estándar',
      subtitle:
        'Ideal para equipos que están dando sus primeros pasos en la digitalización de procesos',
      features: [
        'Todo lo incluido en el Plan Básico +',
        'Respuestas ilimitadas de formularios',
        'Datos almacenados por siempre',
        'Constructor de formularios avanzado',
        'Generador de informes PDF personalizados',
        'Permisos de usuarios editables',
        'Integración de datos con otras plataformas',
        'Chat entre usuarios en la App',
        'Soporte email / teléfono / chat',
      ],
    },
    premium: {
      title: 'Premium',
      subtitle:
        'Para empresas que requieren una visión integral y en tiempo real de todas sus operaciones',
      features: [
        'Todo lo incluido en el Plan Estándar +',
        'Dashboards avanzados',
        'Soporte API',
        'Resolución de fotografías configurable',
        'Ejecutivo de cuentas asignado',
        'Soporte en flujos de trabajo',
      ],
      buttonText: 'Cambiar a Premium',
    },
    enterprise: {
      title: 'Enterprise',
      subtitle: 'Para grandes empresas que buscan una solución y soporte personalizado',
      features: [
        'Todo lo incluido en el Plan Premium +',
        'Implementación personalizada',
        'Soporte preferencial 24/7',
      ],
      buttonText: 'Hablar con ventas',
    },
    features: 'Funcionalidades',
    currentPlan: 'Plan actual',
    start: 'Empezar',
    mostPopular: 'Más popular',
    free: 'Gratis',
    monthlyPerUser: 'mensual por usuario',
    users: 'usuarios',
    standardBold: 'estándar',
    off: 'off',
    finishesIn: 'Termina en',
    ofDiscount: 'de descuento.',
    days: 'Días',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
    salesLink: 'https://www.vyte.in/franciscocuevas',
    activePayment: {
      demoSubscription: 'Suscripción pagada',
      demoSubscriptionDescription: 'Tu plan actual incluye ',
      demoSubscriptionDescription1: ' usuario(s).',
      demoSubscriptionDescription2: 'Puedes invitar más dando clic en el botón.',
      inviteUsers: 'Invitar usuarios',
      demoBannerDescription: '¡No tienes pagos pendientes!',
      demoBannerDescription2:
        'Si tienes alguna pregunta sobre tu suscripción, no dudes en ponerte en contacto con nuestro equipo.',
      moreInfo: 'Más información',
      viewBillingDetails: 'Ver detalles de facturación',
      changeUsers: 'Cambiar número de usuarios',
      modify: 'Modificar',
      monthlyValue: 'Valor mensual',
      annualValue: 'Valor anual',
      total: 'Total',
      valuesMayDiffer: '*Valores pueden cambiar en base a su moneda local.',
      modifyUsersSuccess: 'Suscripción actualizada con éxito',
      modifyUsersError:
        'Ocurrió un error al actualizar la suscripción, por favor intente nuevamente o comuníquese con soporte.',
      confirmModifyUsers: '¿Estás seguro que quieres cambiar el número de usuarios?',
      confirmModifyUsersDescription:
        'Si cambias el número de usuarios, el valor de tu suscripción cambiará en consecuencia y se cobrará la diferencia si corresponde.',
    },
  },
  findings: {
    title: 'Hallazgos',
    new: 'Nuevos',
    all: 'Todos',
    createdBy: 'Creado por',
    expires: 'Vence',
    type: 'Tipo',
    description: 'Descripción',
    searchPlaceholder: 'Buscar por nombre, descripción, autor o ID',
    createdIn: 'Creado en',
    originQuestion: 'Pregunta',
    note: 'Nota',
    viewForm: 'Ver formulario',
    createYourFirstFinding: 'Crea tu primer hallazgo',
    emptyState:
      'Agrega una pregunta de tipo Checklist a un formulario nuevo o existente para comenzar a crear hallazgos',
    goToForms: 'Ir a formularios',
    detailEmptyState: 'Selecciona un hallazgo para ver el historial',
    findingDetail: 'Detalle del hallazgo',
    idCopied: 'ID copiado!',
    delete: 'Eliminar',
    deletedSuccessfully: 'Hallazgo eliminado exitosamente',
    tabs: {
      inProgress: 'En progreso',
      closed: 'Cerrados',
    },
    options: {
      viewDetails: 'Ver detalle',
      addImage: 'Enviar imagen',
      sendFormAnswers: {
        menu: 'Enviar respuesta formulario',
        title: 'Respuesta formulario',
        button: 'Enviar a hallazgo',
        cancel: 'Cancelar',
        description: 'Selecciona la respuesta que deseas enviar al hallazgo',
        searchPlaceholder: 'Buscar formulario',
        answeredBy: 'Respondido por',
        updatedAt: 'Actualizado en',
        noResults: 'No se encontraron respuestas',
        seeAnswer: 'Ver respuesta',
        noAnswers: 'No tienes respuestas en este momento',
      },
      sendForms: {
        button: 'Enviar',
        menu: 'Enviar formulario',
        sync: 'Sincronizando',
      },
      share: {
        menu: 'Compartir enlace',
        title: 'Compartir enlace',
        text: 'Cualquier persona con el link puede acceder al hallazgo',
        activate: 'Habilitar',
        deactivate: 'Deshabilitar',
        loginTitle: '¡Bienvenido a DataScope!',
        loginDescription: 'Para continuar, por favor ingresa tu nombre y correo electrónico',
        loginNamePlaceholder: 'Tu nombre',
        loginEmailPlaceholder: 'Correo electrónico',
      },
    },
    createFinding: {
      title: 'Crear hallazgo',
      requiredInformation: 'Información requerida',
      optionalInformation: 'Información opcional',
      cancel: 'Cancelar',
      create: 'Crear',
      name: 'Nombre',
      type: 'Tipo de Hallazgo',
      description: 'Descripción',
      assignees: 'Responsable(s)',
      dueDate: 'Vencimiento',
      location: 'Lugar',
      inviteParticipants: 'Invitar participantes',
      findingSuccess: 'Hallazgo creado exitosamente',
      findingError: 'Error al crear hallazgo',
      findingCreated: 'Hallazgo creado',
      editInFindings: "Dirígete a la sección de 'Hallazgos' para realizar algún cambio",
    },
    editFinding: {
      title: 'Editar hallazgo',
      update: 'Actualizar',
      findingSuccess: 'Hallazgo actualizado exitosamente',
      findingError: 'Error al actualizar hallazgo',
    },
    closeFinding: {
      title: 'Cerrar hallazgo',
      instruction: 'Completa la siguiente información para cerrar el hallazgo',
      closingNoteLabel: '¿En qué estado cerró el hallazgo?',
      cancel: 'Cancelar',
      confirm: 'Cerrar hallazgo',
    },
    status: {
      title: 'Estado',
      open: 'Abierto',
      in_progress: 'En curso',
      paused: 'Detenido',
      closed: 'Cerrado',
    },
    priority: {
      title: 'Prioridad',
      low: 'Baja',
      medium: 'Media',
      high: 'Alta',
      critical: 'Crítica',
    },
    deleteWarningConfirm: '¿Estás seguro que quieres eliminar este hallazgo?',
    deleteWarningConfirmDescription:
      'Se eliminará permanentemente y no podrás recuperarlo después.',
    chat: {
      events: {
        finding_created: 'Hallazgo creado',
        finding_closed: 'finalizó el hallazgo',
        finding_closed_expired:
          'finalizó el hallazgo después de la fecha de vencimiento estipulada',
        status: 'actualizó el estado a',
        priority: 'actualizó la prioridad a',
        description: 'actualizó la descripción del hallazgo a',
        expiration_date: 'actualizó la fecha de vencimiento a',
        name: 'actualizó el nombre del hallazgo a',
        location: 'actualizó el lugar del hallazgo a',
        added_assignees: 'agregó a',
        removed_assignees: 'eliminó a',
        assignees_singular: 'como responsable',
        assignees_plural: 'como responsables',
        added_invitees: 'agregó a',
        removed_invitees: 'eliminó a',
        invitees_singular: 'como participante',
        invitees_plural: 'como participantes',
      },
      image: {
        error: 'Error',
        sync: 'Sincronizando Imagen',
      },
    },
    export: {
      pdf: {
        title: 'Exportar PDF',
        tooltip: 'Exportar PDF',
        info: 'Exportarás toda la información incluida en el hallazgo. Una vez lo exportes, el link de descarga te llegará al mail.',
        exportSuccess: 'PDF de Hallazgo exportado exitosamente',
        exportError: 'Error al exportar PDF de Hallazgo',
        buttonTitle: 'PDF',
      },
      all: {
        title: 'Exportar todo',
        tooltip: 'Exportar a Excel',
        info: 'Exportarás las siguientes variables en formato Excel: Nombre, descripción, responsables, participantes, vencimiento, prioridad y lugar. Una vez lo exportes, el link de descarga te llegará al mail.',
        exportSuccess: 'Hallazgos exportados exitosamente',
        exportError: 'Error al exportar Hallazgos',
        buttonTitle: 'Excel',
      },
      emailInputLabel: 'Email',
      cancel: 'Cancelar',
      confirm: 'Exportar',
    },
    landing: {
      headerTitle:
        'Soluciona hallazgos encontrados de forma rápida, con trazabilidad de inicio a fin',
      infoTitle1:
        'Podrás notificar a las personas correctas para generar un flujo de trabajo ideal y tener un seguimiento de la solución del hallazgo.',
      actionButton: 'Comenzar prueba gratuita',
      infoDemoTime: 'Prueba hallazgos durante 30 días gratis',
      infoDisclaimer:
        'Una vez finalice la prueba gratuita podrás seguir creando hallazgos, pero no podrás acceder a ellos',
      headerImage:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-gif-es.gif',
      featuresTitle: 'Todo lo que necesitas',
      feature1Description: 'Crea hallazgos de forma personalizada según su prioridad',
      feature2Description: 'Mantén un registro de actualizaciones del hallazgo',
      feature3Description: 'Realiza inspecciones y enlaza un hallazgo a esta',
      feature1Image:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-feature1-es.png',
      feature2Image:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-feature2-es.png',
      feature3Image:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-feature3-es.png',
      footerImage:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-notification-es.png',
      infoTitle2: 'Asegúrate de no perder ningún detalle al seguir la evolución del hallazgo',
      featuresMobileImage:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-features-responsive-es.png',
    },
    demoExpired: {
      title: '¡Tu prueba gratuita ha terminado!',
      description:
        'Para acceder a tus datos, te invitamos a adquirir el módulo de Hallazgos. Puedes acceder a él dando clic en el botón',
      actionButton: 'Activar Hallazgos',
    },
    suscriptionCancelled: {
      title: 'Tu suscripción ha expirado',
      description:
        'Para acceder a tus datos, te invitamos a pagar tu suscripción al módulo de Hallazgos',
    },
    adminAlert:
      'Podrás ver todos los hallazgos, pero para recibir actualizaciones debes asignarte como responsable o invitado.',
    configure: 'Configurar',
    config: {
      findingTypes: 'Tipos de hallazgos',
      createType: 'Crear tipo',
      editType: 'Editar',
      deleteType: 'Eliminar',
      deleteTypeDisabled: 'No puedes quedarte sin tipos de hallazgos',
    },
    integrations: {
      title: 'Integracion Hallazgos',
      button: 'Integraciones',
      gsheetsMenuName: 'Google Sheets',
      gsheetsIntegrationLabel: 'Ir a Google Sheets',
      infoTextPart1:
        'Para garantizar una integración adecuada, privada y segura, comparte tu documento con permisos de',
      infoTextPart2: 'editor',
      infoTextPart3: 'únicamente a',
      inputLabel: 'Nuevo Enlace',
      inputPlaceholder: 'Ingresa el enlace del documento a integrar',
      save: 'Guardar',
      cancel: 'Cancelar',
      copyUrl: '¡URL copiada!',
      created:
        '¡Integración configurada exitosamente! Por favor, espera unos segundos para que se reflejen los cambios',
      saved: '¡Cambios guardados con éxito!',
      savingError: 'Error al configurar la integración',
      confirmation: '¿Quieres guardar los cambios realizados antes de salir?',
      webhooks: 'Webhooks',
      webhookUrl: 'Webhook URL',
      infoWebhooksTextPart1:
        'Automatiza tus flujos de trabajo permitiendo que DataScope active procesos en plataformas externas.',
      infoWebhooksTextPart2: 'Mantén todo sincronizado sin esfuerzo.',
    },
    findingTypes: {
      noType: 'Otro',
      createTitle: 'Crear Tipo de Hallazgo',
      editTitle: 'Editar Tipo de Hallazgo',
      name: 'Nombre',
      assignees: 'Responsable (Asignar a)',
      assigneesCaption: 'Si no asignas responsable, en la app lo podrás hacer',
      location: 'Lugar',
      locationCaption: 'Si no asignas un lugar, en la app lo podrás hacer',
      save: 'Guardar',
      update: 'Actualizar',
      cancel: 'Cancelar',
      dueDateAndPriority: 'Vencimiento y Prioridad',
      priorityInformationBanner:
        'Puedes seleccionar una prioridad como predeterminada (opcional). El creador del hallazgo no podrá cambiarla, pero verá su descripción.',
      dueDate: 'Vencimiento',
      dueDateCaption: 'Esta configuración empezará a contar desde la creación del hallazgo',
      dueDateTypes: {
        days: 'Días',
        hours: 'Horas',
      },
      priority: 'Prioridad',
      description: 'Descripción',
      descriptionPlaceholder: 'Escribe la descripción o instrucción de la prioridad',
      additionalInformation: 'Información Adicional (Opcional)',
      additionalInformationBanner:
        'Asignar formularios pre determinados para que respondan las personas que participarán en el hallazgo',
      typeCreatedSuccess: 'Tipo de hallazgo creado con éxito',
      typeCreatedError: 'Error al crear tipo de hallazgo',
      typeDeletedSuccess: 'Tipo de hallazgo eliminado con éxito',
      typeDeletedError: 'Error al eliminar tipo de hallazgo',
      typeUpdatedSuccess: 'Tipo de hallazgo actualizado con éxito',
      typeUpdatedError: 'Error al actualizar tipo de hallazgo',
      deleteTypeAlertTitle: '¿Estás seguro que quieres eliminar este tipo de hallazgo?',
      deleteTypeAlertMessage:
        'Si lo eliminas ya no lo podrás recuperar, pero la información relacionada con este permanecerá en la plataforma',
    },
  },
  commonComponents: {
    formSelector: {
      title: 'Formularios',
      addForm: 'Agregar formulario',
      searchPlaceholder: 'Buscar formulario, carpeta...',
      forms: 'Formularios',
      folders: 'Carpetas',
      save: 'Guardar',
      cancel: 'Cancelar',
      see: 'Ver',
      more: 'más',
      close: 'Cerrar',
      maxFormsSelected: 'Máximo de formularios seleccionados',
    },
    confirmActionAlert: {
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },
  },
  webHooks: {
    title: 'WebHooks',
    new: {
      title: 'Crear nuevo WebHook',
      token: 'Token:',
      expires_in: 'Expira en:',
      expires_at: 'Expira a las:',
      form: 'Formulario',
      select_form: 'Seleccionar un formulario',
      select_all_forms: 'Todos los formularios',
      url: 'Url de la conexión',
      example_url: 'http://www.tuservidor.com/servicio',
      send_modifications: 'Enviar modificaciones',
      oauth_checkbox: 'Autenticar con OAuth2',
      save: 'Guardar',
      back: 'Volver a WebHooks',
      configure_oauth: 'Configurar OAuth 2.0',
      access_token: 'URL del Token de Acceso',
      client_id: 'ID del cliente',
      client_secret: 'Llave secreta del cliente',
      scope: 'Scope',
      save_oauth: 'Guardar Configuración OAuth',
      save_oauth_success: 'Token de OAuth creado con éxito',
      save_oauth_error: 'Hubo un error al crear el token de OAuth',
      token_details: 'Detalles del Token',
      create_webhook: 'Crear WebHook',
      create_webhook_success: 'WebHook creado con éxito',
      create_webhook_error: 'Hubo un error al crear el WebHook',
    },
    update: {
      update_webhook: 'Actualizar WebHook',
      update_webhook_success: 'WebHook actualizado con éxito',
      update_webhook_error: 'Hubo un error al actualizar el WebHook',
    },
  },
  answerView: {
    alreadyRejectedSignature: 'Rechazaste firmar este formulario',
    rejectConfirmationTitle: '¿Estás seguro que quieres rechazar la solicitud?',
    rejectConfirmationDescription: 'Una vez rechazado, no podrás volver a firmar este formulario.',
    reject: 'Rechazar',
    rejectSuccess: '¡Solicitud de firma rechazada!',
    rejectError: 'No se pudo rechazar la firma',    
    location: 'Lugar',
    address: 'Dirección',
    assignee: 'Responsable',
    taskInformation: 'Información de la tarea asignada',
    answeredFromMobile: 'Enviado desde aplicación móvil',
    answeredFromWeb: 'Enviado desde la web',
    verifySignatureAlert:
      'Para firmar el formulario primero debes verificar tu firma,  esto solo lo harás una vez.',
    verifySignature: 'Verificar firma',
    sign: 'Firmar',
    alreadySignedAlert: 'Ya firmaste este formulario',
    goToSignatures: 'Ir a firmas',
    signatureConfigAlert: 'Por favor revisa la configuración de firma electrónica',
    signSuccess: '¡Formulario firmado exitosamente!',
    signError: 'Ocurrió un error al firmar el formulario',
    signatureVerifySuccess:
      '¡Verificaste tu firma electrónica exitosamente! Ahora podrás firmar todos tus formularios',
    signatureVerifyError: 'Ocurrió un error al verificar tu firma electrónica',
    checkedCountInfo1: 'Elementos seleccionados de una lista de',
    checkedCountInfo2: 'elementos.',
    checkedCountInfo3: 'Ver lista',
    group: 'Grupo',
    attachedFiles: 'Estos son los archivos adjuntos a esta pregunta',
    metadataComments: {
      item: 'Ítem',
      answer: 'Respuesta',
      description: 'Nota',
      images: 'Imágenes',
      issues: 'Hallazgo creado',
      viewIssue: 'Ver hallazgo',
    },
    expiredLink: {
      description: 'Este link ha vencido. Solicita uno nuevo dando click en el botón',
      button: 'Solicitar link',
      newLinkRequestedSuccess: 'El link ha sido enviado a tu correo',
      newLinkRequestedError: 'Ocurrió un error al solicitar el link',
    },
    externalUserSign: {
      title: 'Registra tu firma',
      registeredDescription:
        'Tu firma se encuentra registrada, por favor confirma tus datos para poder continuar',
      unregisteredDescription:
        'Tu firma no se encuentra registrada, para poder firmar te invitamos a completar los siguientes datos',
      name: 'Nombre completo',
      company: 'Empresa',
      role: 'Cargo',
      confirm: 'Continuar',
      cancel: 'Cancelar',
      success: '¡Formulario firmado exitosamente!',
      error: 'Ocurrió un error al firmar el formulario',
    },
  },
  electronic_signature: {
    verifySignature: 'Verificar firma',
    annex: 'Anexo',
    sendCode: 'Enviar código',
    errorSendingCode: 'Ha ocurrido un error enviando el código',
    successSendingCode: 'Código enviado correctamente',
    code_sent_to_email: 'Enviamos el código de verificación a tu correo:',
    resendCodeLabel: 'No recibiste el código? ',
    resend: 'Reenviar',
    incorrect_code: 'Código incorrecto, te quedan {remaining_attempts} intentos',
    maxAttemptsReached: 'Has alcanzado el máximo de intentos, se reenvió un nuevo código',
    errorVerifyingCode: 'Error al verificar el código',
    back: 'Volver',
    share: 'Compartir',
    signatures: {
      title: 'Mis firmas',
      signature_date: 'Fecha de firma',
      noPendingSignatures: '¡Felicitaciones! No tienes firmas pendientes en este momento',
      noCompletedSignatures: 'No tienes firmas completadas en este momento',
      selected: 'Seleccionados',
      sign: 'Firmar',
      viewAndDownload: 'Ver y descargar',
      massiveSignError: 'Ocurrió un error al firmar los formularios',
      massiveSignSuccess: 'Formularios firmados exitosamente',
      downloadError: 'Ocurrió un error al descargar el archivo',
    },
    requests: {
      title: 'Solicitudes enviadas',
      mandatory: 'Firma obligatoria',
      optional: 'Firma opcional',
      ongoing: 'En curso',
      completed: 'Finalizadas',
      searchPlaceholder: 'Buscar por usuario o formulario...',
      form: 'Formulario',
      requestDate: 'Fecha solicitud',
      requestedBy: 'Solicitado por',
      goToSign: 'Ir a firmar',
      signatureDate: 'Fecha firma',
      signatures: 'Firmas',
      signed: 'Ya firmaron',
      pending: 'Pendientes',
      actions: 'Acciones',
      deleteRequest: 'Eliminar solicitud',
      deleteSuccess: 'Solicitud eliminada con éxito',
      deleteError: 'Ocurrió un error al eliminar la solicitud',
      noOngoingRequests: '¡Todo en orden! No tienes solicitudes en curso en este momento',
      noCompletedRequests: 'No tienes solicitudes finalizadas en este momento',
      shareModal: {
        title: 'Compartir solicitud de firma finalizada',
        share: 'Compartir',
        infoAlert:
          'Se enviará un archivo por correo electrónico. Los destinatarios podrán acceder a los detalles y revisarlos fácilmente.',
        emailInstruction: 'Ingresa la direccion a la que deseas enviar el archivo.',
        inputInstruction: 'Puedes agregar tareas rápidamente separándolas con comas “,”.',
        cancel: 'Cancelar',
      },
      shareSuccess: 'Solicitud compartida exitosamente',
      shareError: 'Ocurrió un error al compartir la solicitud',
      deleteWarningTitle: '¿Estás seguro que quieres eliminar esta solicitud?',
      deleteWarningMessage:
        'Las firmas ya realizadas seguirán siendo válidas y quedarán guardadas en el historial.',
      timeline: {
        title: 'Detalle de solicitud',
        close: 'Cerrar',
        createdRequired: '{user} ha enviado una solicitud de firma obligatoria',
        createdOptional: '{user} ha enviado una solicitud de firma voluntaria',
        signed: '{user} firmó el documento',
        rejected: '{user} rechazó la solicitud',
      },
      tooltip: {
        signed: 'Solicitud finalizada',
        rejected: 'Solicitud rechazada',
      }
    },
    promo: {
      title: 'Firma reportes de forma digital y asegura procesos',
      description:
        'Con el módulo de Firmas podrás solicitar y firmar procesos de forma simple y segura.',
      actionButton: '¡Quiero implementar Firmas!',
      moreInfoTitle: '¡Garantiza la trazabilidad y reduce el margen de error!',
      infoPoint1: 'Solicita firmas a tu equipo en terreno y envía notificaciones automáticas',
      infoPoint2: 'Garantiza el cumplimiento de tus procesos',
      infoPoint3: 'Aprobado por la Dirección del Trabajo (válido solo en Chile)',
      infoPoint4: 'Accede a tus datos de forma segura desde cualquier dispositivo',
      moreInfoButton: '¡Quiero más información!',
      moreInfoLink: 'https://help.mydatascope.com/es/articles/8728763-firma-electronica-verificada',
    },
  },
  resume: {
    stats: {
      assignedTasks: 'Tareas asignadas',
      totalAssignedTasks: 'Total Asignadas',
      completedTasks: 'Tareas completadas',
      totalCompletedTasks: 'Total Completadas',
      forms: 'Formularios',
      totalForms: 'Total Formularios',
      locations: 'Lugares',
      visitedLocations: 'Lugares Visitados',
    },
    emptyState: {
      description:
        '¡Aún no has respondido ningún formulario! Empieza a optimizar tus procesos creando el primero',
      button: 'Responder un formulario ahora',
    },
  },
};
