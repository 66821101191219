import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { webFormsApi } from '../../axios';
import {
  MetadataQuestion,
  Question,
  QuestionType,
  RepeatableQuestionAnswerValue,
  RepeatedQuestionValue,
  WebFormsState,
} from '../../types';
import { RootState } from '../store';

const initialState: WebFormsState = {
  formId: '',
  formName: '',
  formCategory: '',
  formDescription: '',
  questions: [],
  metadata: {},
  answers: {},
  skippedQuestions: [],
  loading: false,
  sections: [],
  currentSection: 0,
  findingsCreated: [],
  automatedSignatureOnResponse: false,
  authorMustSign: false,
};

const complexAnswerTypes = [
  'select_check_metadata',
  'select_text_metadata',
  'select_number_metadata',
  'select_option_metadata',
  'attachment',
  'date',
];

function answersForQuestions(questions: WebFormsState['questions']) {
  if (!questions) return {};
  return questions.reduce((acc, question) => {
    const value = question.type === 'table' ? { 0: {} } : undefined;
    return {
      ...acc,
      [question.id]: {
        value,
      },
    };
  }, {});
}

function formatAnswerData(
  answers: WebFormsState['answers'],
  questions: WebFormsState['questions'],
) {
  Object.keys(answers).forEach((questionId) => {
    const answer = { ...answers[questionId] };
    answer.edited = false;
    const question = questions.find((q) => q.id === questionId);
    if (question?.type === 'table') {
      const tableValue = answer.value as RepeatableQuestionAnswerValue;
      Object.keys(tableValue).forEach((rowId) => {
        const row = tableValue[parseInt(rowId)];
        const newRowValue = question.questions.reduce((acc, subQuestion, subQuestionIndex) => {
          const subAnswer = row[parseInt(subQuestion.id)];
          if (subAnswer?.value) {
            const column = {
              ...subAnswer,
              id: subQuestion.id,
              name: subQuestion.name,
              type: subQuestion.type,
              question_name: question.name,
              sectionId: question.sectionId,
            };
            // if subQuestion is of a complex type, add the metadata attribute to the column
            if (complexAnswerTypes.includes(subQuestion.type)) {
              const metadataQuestion = subQuestion as MetadataQuestion;
              column.metadata = metadataQuestion.metadata;
              column.metadata_code = metadataQuestion.metadata;
            }
            acc[subQuestionIndex] = column;
          }
          return acc;
        }, {} as Record<number, RepeatedQuestionValue>);
        tableValue[parseInt(rowId)] = newRowValue;
      });
    }
  });
  return answers;
}

const evaluateEquality = (
  question: Question,
  skipDependentAnswer: unknown,
  skipLogicQuestionType: QuestionType,
) => {
  if (complexAnswerTypes.includes(skipLogicQuestionType)) {
    if (skipLogicQuestionType === 'date') return skipDependentAnswer.toString().length === 0;
    else if (skipLogicQuestionType === 'select_check_metadata') {
      const answer = skipDependentAnswer as Record<string, boolean>;
      return Object.values(answer).length === 0;
    } else {
      const answer = skipDependentAnswer as Record<string, string>;
      return Object.values(answer).length === 0;
    }
  } else return skipDependentAnswer === question.skipLogicValue;
};

const getSkippedQuestions = (
  questions: WebFormsState['questions'],
  answers: WebFormsState['answers'],
) => {
  return questions.reduce((acc, question) => {
    if (question.skipLogic === '1') {
      const skipDependentAnswer = answers[question.skipLogicQuestion]?.value || '';
      const skipLogicQuestionType = questions.find(
        (q) => question.skipLogicQuestion === q.id,
      )?.type;
      const match = evaluateEquality(question, skipDependentAnswer, skipLogicQuestionType);
      const shouldBeSkipped = question.skipLogicType === 'equal' ? match : !match;
      if (shouldBeSkipped) acc.push(question.id);
    }
    return acc;
  }, []);
};

const getUniqueSections = (questions: WebFormsState['questions']) => {
  return Array.from(new Set(questions?.map((question) => question.label)));
};

const getTemplateForm = createAsyncThunk(
  'getTemplateForm',
  async ({ formId, shareId }: { formId: string; shareId: string }) => {
    return await webFormsApi.getTemplateForm(formId, shareId);
  },
);

const getForm = createAsyncThunk(
  'getForm',
  async ({ formId, shareToken }: { formId: string; shareToken: string }) => {
    return await webFormsApi.getForm(formId, shareToken);
  },
);

const getFormAnswer = createAsyncThunk(
  'getFormAnswer',
  async ({ formAnswerId }: { formAnswerId: string }) => {
    return await webFormsApi.getFormAnswer(formAnswerId);
  },
);

export const webFormsSlice = createSlice({
  name: 'webForms',
  initialState: initialState,
  reducers: {
    updateAnswerValue: (state, action) => {
      const { questionId, value, editMode } = action.payload;
      state.answers = {
        ...state.answers,
        [questionId]: {
          ...state.answers[questionId],
          value,
          edited: editMode,
        },
      };
      state.skippedQuestions = getSkippedQuestions(state.questions, state.answers);
    },
    updateRepeatableAnswerValue: (state, action) => {
      const { questionId, value, groupIndex, questionIndex, editMode } = action.payload;
      const answer = state.answers[questionId];
      const tableValue = answer.value as RepeatableQuestionAnswerValue;
      const row = tableValue[groupIndex];
      const newRowValue = { ...row, [questionIndex]: value };
      if (isEmpty(value)) {
        delete newRowValue[questionIndex];
      }
      tableValue[groupIndex] = newRowValue;
      state.answers = {
        ...state.answers,
        [questionId]: {
          ...state.answers[questionId],
          value: tableValue,
          edited: editMode,
        },
      };
    },
    addRepeatableRow: (state, action) => {
      const { questionId, editMode } = action.payload;
      const answer = state.answers[questionId];
      const tableValue = answer.value as RepeatableQuestionAnswerValue;
      tableValue[Object.keys(tableValue).length] = {};
      state.answers = {
        ...state.answers,
        [questionId]: {
          ...state.answers[questionId],
          value: tableValue,
          edited: editMode,
        },
      };
    },
    removeRepeatableRow: (state, action) => {
      const { questionId, groupIndex, editMode } = action.payload;
      const answer = state.answers[questionId];
      const tableValue = answer.value as RepeatableQuestionAnswerValue;
      delete tableValue[groupIndex];
      state.answers = {
        ...state.answers,
        [questionId]: {
          ...state.answers[questionId],
          value:
            Object.keys(tableValue).length === 0 ? { 0: {} } : { ...Object.values(tableValue) },
          edited: editMode,
        },
      };
    },
    updateAnswerError: (state, action) => {
      const { questionId, error } = action.payload;
      state.answers = {
        ...state.answers,
        [questionId]: {
          ...state.answers[questionId],
          error,
        },
      };
    },

    updateAnswerLoading: (state, action) => {
      const { questionId, loading } = action.payload;
      state.answers = {
        ...state.answers,
        [questionId]: {
          ...state.answers[questionId],
          loading,
        },
      };
    },

    updateAnswersErrors: (state, action) => {
      const { answersErrors }: { answersErrors: { questionId: string; error: unknown }[] } =
        action.payload;
      state.answers = answersErrors.reduce((acc, { questionId, error }) => {
        return {
          ...acc,
          [questionId]: {
            ...state.answers[questionId],
            error,
          },
        };
      }, state.answers);
    },

    resetForm: (state) => {
      state.answers = answersForQuestions(state.questions);
      state.currentSection = 0;
    },

    nextSection: (state) => {
      if (state.currentSection < state.sections.length - 1) {
        state.currentSection += 1;
      }
    },

    previousSection: (state) => {
      if (state.currentSection > 0) {
        state.currentSection -= 1;
      }
    },

    addFinding: (state, action) => {
      const { findingId } = action.payload;
      state.findingsCreated.push(findingId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplateForm.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTemplateForm.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTemplateForm.fulfilled, (state, action) => {
      state.formName = action.payload.form_name;
      state.formCategory = action.payload.form_category;
      state.formDescription = action.payload.description;
      state.questions = action.payload.questions;
      state.metadata = action.payload.metadata;
      state.answers = answersForQuestions(action.payload.questions);
      state.loading = false;
    });

    builder.addCase(getForm.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getForm.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getForm.fulfilled, (state, action) => {
      state.formId = action.meta.arg.formId;
      state.formName = action.payload.form_name;
      state.questions = action.payload.questions;
      state.metadata = action.payload.metadata;
      state.sections = getUniqueSections(action.payload.questions);
      state.answers = answersForQuestions(action.payload.questions);
      state.skippedQuestions = getSkippedQuestions(action.payload.questions, state.answers);
      state.automatedSignatureOnResponse = action.payload.automated_signature_on_response;
      state.authorMustSign = action.payload.author_must_sign;
      state.loading = false;
    });
    builder.addCase(getFormAnswer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFormAnswer.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getFormAnswer.fulfilled, (state, action) => {
      state.answers = { ...state.answers, ...formatAnswerData(action.payload, state.questions) };
      state.skippedQuestions = getSkippedQuestions(state.questions, state.answers);
      state.loading = false;
    });
  },
});

export const {
  updateAnswerValue,
  updateRepeatableAnswerValue,
  updateAnswerError,
  updateAnswerLoading,
  updateAnswersErrors,
  resetForm,
  nextSection,
  previousSection,
  addFinding,
  addRepeatableRow,
  removeRepeatableRow,
} = webFormsSlice.actions;

export { getForm, getFormAnswer, getTemplateForm };

export const unsupportedQuestionTypes = ['barcode', 'multi_photo'];
const stateAnswers = (state: RootState) => state.webForms.answers;
const stateQuestions = (state: RootState) => state.webForms.questions;
const stateSkippedQuestions = (state: RootState) => state.webForms.skippedQuestions;

export const getQuestions = createSelector(
  [stateQuestions, stateSkippedQuestions],
  (questions, skippedQuestions) => {
    return questions.filter(
      (question) =>
        !skippedQuestions.includes(question.id) &&
        !unsupportedQuestionTypes.includes(question.type),
    );
  },
);

export const getQuestionsWithSkipped = createSelector([stateQuestions], (questions) => {
  return questions.filter((question) => !unsupportedQuestionTypes.includes(question.type));
});

export function getAnswer(state: RootState, questionId: string) {
  return state.webForms.answers[questionId];
}

export const getAnswersWithoutSkipped = createSelector(
  [stateAnswers, stateSkippedQuestions],
  (answers, skippedQuestions) => {
    return Object.keys(answers)
      .filter((id) => !skippedQuestions.includes(id))
      .reduce((result, key) => {
        result[key] = answers[key];
        return result;
      }, {} as WebFormsState['answers']);
  },
);

export function getMetadata(state: RootState, metadataId: string) {
  return state.webForms.metadata[metadataId];
}

export default webFormsSlice.reducer;
