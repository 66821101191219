import axios from 'axios';
import {
  CreateTaskAssignBody,
  GetFilterMetadataResponse,
  GetTaskAssignsParams,
  GetTaskAssignsResponse,
  GetTasksParams,
  GetTasksUrlParams,
} from '../types/taskAssigns';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

/** TODO: Delete when TasksAssignedGroupV1 is full released */
const getTaskAssigns = async ({
  page = 1,
  taskAssignID,
  userId,
  locationId,
  taskFormId,
  state,
  startDate,
  endDate,
}: GetTaskAssignsParams) => {
  let getTaskAssignsUrl = `/task_assigns_react?page=${page}`; // base url

  // Append other parameters if they are provided
  if (taskAssignID) getTaskAssignsUrl += `&assignID=${taskAssignID}`;
  if (userId) getTaskAssignsUrl += `&user_id=${userId}`;
  if (locationId) getTaskAssignsUrl += `&location_id=${locationId}`;
  if (taskFormId) getTaskAssignsUrl += `&task_form_id=${taskFormId}`;
  if (state) getTaskAssignsUrl += `&state=${state}`;
  if (startDate) getTaskAssignsUrl += `&start=${startDate}`;
  if (endDate) getTaskAssignsUrl += `&end=${endDate}`;

  const response = await axios.get<GetTaskAssignsResponse>(getTaskAssignsUrl, {
    withCredentials: true,
  });
  return response;
};

const getTasks = async ({
  page = 1,
  taskAssignID,
  userId,
  locationId,
  taskFormId,
  state,
  startDate,
  endDate,
  sortBy,
  order,
}: GetTasksParams) => {
  let getTaskAssignsUrl = `/task_assigns_react?page=${page}`;
  const params: GetTasksUrlParams = {
    assignID: taskAssignID,
    user_id: userId,
    location_id: locationId,
    task_form_id: taskFormId,
    state,
    start: startDate,
    end: endDate,
    sort_by: sortBy,
    order,
  };
  Object.entries(params).forEach(([key, value]) => {
    if (value) getTaskAssignsUrl += `&${key}=${value}`;
  });

  const response = await axios.get<GetTaskAssignsResponse>(getTaskAssignsUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
};

const getTasksBoxes = async ({
  page = 1,
  taskAssignID,
  userId,
  locationId,
  taskFormId,
  state,
  startDate,
  endDate,
  sortBy,
  order,
}: GetTasksParams) => {
  let getTaskAssignsUrl = `/task_assigns_react_boxes?page=${page}`;
  const params: GetTasksUrlParams = {
    assignID: taskAssignID,
    user_id: userId,
    location_id: locationId,
    task_form_id: taskFormId,
    state,
    start: startDate,
    end: endDate,
    sort_by: sortBy,
    order,
  };
  Object.entries(params).forEach(([key, value]) => {
    if (value) getTaskAssignsUrl += `&${key}=${value}`;
  });

  const response = await axios.get<GetTaskAssignsResponse>(getTaskAssignsUrl, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
};

const getLightFilterMetadata = async () => {
  const getLightFilterMetadataUrl = '/filter_metadata';
  const response = await axios.get<GetFilterMetadataResponse>(getLightFilterMetadataUrl, {
    withCredentials: true,
  });
  return response;
};

const createTaskAssign = async (taskAssign: CreateTaskAssignBody) => {
  const response = await axios.post('/task_assigns_create.json', taskAssign, {
    withCredentials: true,
    headers: {
      accept: 'application/json',
    },
  });
  return response;
};

const updateTaskAssign = async (taskId: number, taskAssign: CreateTaskAssignBody) => {
  const response = await axios.put(`/task_assign_update/${taskId}`, taskAssign, {
    withCredentials: true,
    headers: {
      accept: 'application/json',
    },
  });
  return response;
};

const notifyUsers = async (taskID: number) => {
  axios.post(`/tasks_notify_react.json`, { task_id: taskID }, { withCredentials: true });
};

const deleteTask = async (taskID: number) => {
  axios.delete(`/task_assigns_delete/${taskID}`, {
    withCredentials: true,
  });
};

const getEditInfo = async (taskID: number) => {
  const response = axios.get(`/task_assign_edit/${taskID}`, {
    withCredentials: true,
  });
  return response;
};

const bulkDeleteTasks = async (selectedTaskIds: number[]) => {
  axios.post(
    '/tasks_bulk_delete_react',
    { form_ids: selectedTaskIds },
    {
      withCredentials: true,
    },
  );
};

const bulkNotifyUsers = async (selectedTaskIds: number[]) => {
  axios.post(
    '/bulk_notify_react',
    { form_ids: selectedTaskIds },
    {
      withCredentials: true,
    },
  );
};

const updateTask = async (
  taskID: string,
  task: CreateTaskAssignBody['task_assign'],
  time: CreateTaskAssignBody['time'],
  todoValue: string,
) => {
  const taskAssign = {
    task_assign: task,
    time,
    'todo-value': todoValue,
  };
  axios.put(`/task_assign_update/${taskID}`, taskAssign, {
    withCredentials: true,
  });
};

const getOldVersion = async () => {
  await axios.get(`/old_task_assigner`, {
    withCredentials: true,
  });
};

export const taskAssignsApi = {
  getTaskAssigns,
  getLightFilterMetadata,
  notifyUsers,
  deleteTask,
  getEditInfo,
  createTaskAssign,
  updateTaskAssign,
  getTasks,
  bulkDeleteTasks,
  bulkNotifyUsers,
  updateTask,
  getOldVersion,
  getTasksBoxes,
};
