import { init, track } from '@amplitude/analytics-browser';

/**
 * A centralized event tracking system.
 * It catch any exception internally.
 * @param event Event name.
 * @param metadata Event metadata, can be undefined or an object with custom attributes.
 * @param strategy Trackers to be activated.
 */
export function datascopeTracker(
  event: string,
  metadata: unknown,
  strategy: 'ALL' | 'AMP' = 'ALL',
) {
  try {
    switch (strategy) {
      case 'ALL':
        // Google Analytics
        // Facebook Pixel
        // Amplitude
        amplitudStrategy(event, metadata);
        break;
      case 'AMP':
        amplitudStrategy(event, metadata);
        break;
    }
  } catch (e) {
    console.log(e);
  }
}
/**
 * Amplitud event tracker
 * @param event Event name.
 * @param metadata Metadata object. Can be undefined
 */
function amplitudStrategy(event: string, metadata: unknown) {
  track(event, metadata);
}
/**
 * Initialize Amplitude / Google Analytics / Facebook Pixel / etc
 */
export function InitializeTrackers() {
  if (process.env.REACT_APP_ENABLE_TRACKING !== 'true') return;
  try {
    init('6846867d9f6178955fb7929e84be476a');
  } catch (e) {
    console.log('Failed to load amplitude analytics', e);
  }
}
