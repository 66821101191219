import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getQueryParams } from '../../utils/utils';
import { webFormsApi } from '../../axios';
import { Finding, FormAnswerWithTaskAssign, WebFormsAnswer } from '../../types';
import { FirestoreContext } from '../../providers';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { useFeatureFlags } from '../../hooks';

interface AnswerViewContextType {
  answerInfo: FormAnswerWithTaskAssign;
  answers: Record<string, WebFormsAnswer<unknown, unknown>>;
  loading: boolean;
  findingsList: Finding[];
  isPublicView: boolean;
  token?: string;
}

const AnswerViewContext = createContext<AnswerViewContextType | null>(null);

export const AnswerViewProvider = ({ children }: { children: ReactNode }) => {
  const { form_answer_id: formAnswerId, token } = getQueryParams();

  const { firestore, user, firebaseConfigured } = useContext(FirestoreContext);
  const { getPremiumFlag } = useFeatureFlags();
  const findingsActivated = !!getPremiumFlag('findings');

  const [answerInfo, setAnswerInfo] = useState<AnswerViewContextType['answerInfo']>(null);
  const [answers, setAnswers] = useState<AnswerViewContextType['answers']>({});
  const [loading, setLoading] = useState(true);
  const [findingsList, setFindingsList] = useState<AnswerViewContextType['findingsList']>([]);

  const isPublicView = !!token;

  const fetchFormAnswerAndTaskInfo = async () => {
    try {
      const response = await webFormsApi.getFormAnswerWithTaskData(
        formAnswerId,
        isPublicView ? token : undefined,
      );
      setAnswerInfo(response);
    } catch (error) {
      console.error('Error fetching form answer and task info:', error);
    }
  };

  const fetchFormAnswerAnswers = async () => {
    try {
      const response = isPublicView
        ? await webFormsApi.getPublicFormAnswer(formAnswerId, token)
        : await webFormsApi.getFormAnswer(formAnswerId);
      setAnswers(response);
    } catch (error) {
      console.error('Error fetching form answers:', error);
    }
  };

  const getFindings = async () => {
    if (!findingsActivated) return;

    try {
      const findingsQuery = query(
        collection(firestore, `datascope/${user.uid}/findings`),
        where('form_answer.id', '==', parseInt(formAnswerId)),
      );

      const findingsSnapshot = await getDocs(findingsQuery);
      const findingsList = findingsSnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as Finding;
      });
      setFindingsList(findingsList);
    } catch (error) {
      console.error('Error fetching findings:', error);
    }
  };

  useEffect(() => {
    const fetchAnswerView = async () => {
      setLoading(true);
      await fetchFormAnswerAndTaskInfo();
      await fetchFormAnswerAnswers();
      setLoading(false);
    };
    fetchAnswerView();
  }, [formAnswerId]);

  useEffect(() => {
    if (!isPublicView && firebaseConfigured) getFindings();
  }, [firebaseConfigured]);

  const answerViewValue = {
    answerInfo,
    answers,
    loading,
    findingsList,
    isPublicView,
    token,
  };
  return (
    <AnswerViewContext.Provider value={answerViewValue}>{children}</AnswerViewContext.Provider>
  );
};

export const useAnswerViewContext = () => useContext(AnswerViewContext);
