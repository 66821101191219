import axios from 'axios';
import {
  FormAnswerWithTaskAssign,
  SubmitWebFormBody,
  WebFormsAnswer,
  WebFormsFormResponse,
  WebFormsTemplateResponse,
} from '../types';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

/// This function is used to upload a file to S3.
// The URL is then split and the query string is removed before returning the URL
// to ensure that signed values are not saved on the answer value.
async function uploadToS3(file: File, shareToken?: string) {
  const fileData = new FormData();

  fileData.append('type', file.type);

  // Special characters cause issues when downloading files from S3
  const sanitizedFileName = encodeURI(file.name).replace(/[^a-zA-Z0-9.]/g, '');
  fileData.append('file_name', sanitizedFileName);
  if (shareToken) fileData.append('share_token', shareToken);

  const { data: presignedPostUrl } = await axios.post<{ url: string; path: string }>(
    `/${shareToken ? 'public_' : ''}get_signed_url`,
    fileData,
    {
      withCredentials: true,
    },
  );

  await axios.put(presignedPostUrl.url, file);

  return presignedPostUrl.url.split('?')[0];
}

async function getTemplateForm(formId: string, shareId: string) {
  const response = await axios.get<WebFormsTemplateResponse>(
    `task_forms_react_public_v2/${formId}?template_share_id=${shareId}`,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function getForm(formId: string, shareToken: string) {
  const url = shareToken
    ? `/task_forms_react_public_v2/${formId}?share_token=${shareToken}`
    : `/task_forms_react_v2/${formId}`;
  const response = await axios.get<WebFormsFormResponse>(url, { withCredentials: true });
  const { data } = response;
  return data;
}

async function getFormAnswer(formAnswerId: string) {
  const response = await axios.get<Record<string, WebFormsAnswer<unknown, unknown>>>(
    `/form_answers_react/${formAnswerId}`,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function getPublicFormAnswer(formAnswerId: string, token: string) {
  const response = await axios.get<Record<string, WebFormsAnswer<unknown, unknown>>>(
    `/form_answers_react_public/${formAnswerId}?token=${token}`,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function getFormAnswerWithTaskData(formAnswerId: string, token?: string) {
  const url = token
    ? `/answer_view_public/${formAnswerId}?token=${token}`
    : `/answer_view/${formAnswerId}`;
  const response = await axios.get<FormAnswerWithTaskAssign>(url, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function submitAnswer(body: SubmitWebFormBody) {
  const response = await axios.post(
    `/upload_${body.share_token ? 'public_' : ''}form_answer`,
    body,
    { withCredentials: true },
  );
  return response;
}

async function updateAnswer(body: SubmitWebFormBody) {
  const response = await axios.post('/edit_form_answer', body, { withCredentials: true });
  return response;
}

export const webFormsApi = {
  uploadToS3,
  getTemplateForm,
  getForm,
  submitAnswer,
  getFormAnswer,
  updateAnswer,
  getFormAnswerWithTaskData,
  getPublicFormAnswer,
};
