import { ReactNode, createContext, useEffect, useState } from 'react';
import { firestoreApi } from '../axios/firestore';
import { initializeApp } from 'firebase/app';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getAuth, signInWithCustomToken, User as FirestoreUser } from 'firebase/auth';
import { getFunctions, Functions } from 'firebase/functions';
import { getStorage, FirebaseStorage } from 'firebase/storage';

interface FirestoreContextType {
  firestore: Firestore;
  user: FirestoreUser;
  firebaseConfigured: boolean;
  functions: Functions;
  storage: FirebaseStorage;
}

export const FirestoreContext = createContext<FirestoreContextType | null>(null);

export const FirestoreProvider = ({ children }: { children: ReactNode }) => {
  const [firestore, setFirestore] = useState<Firestore | null>(null);
  const [user, setUser] = useState<FirestoreUser | null>(null);
  const [configured, setConfigured] = useState(false);
  const [functions, setFunctions] = useState(null);
  const [storage, setStorage] = useState(null);

  const initializeFirebase = async () => {
    try {
      const authData = await firestoreApi.getFirebaseAuthTokens();

      if (!authData.firebase) return;

      const firebaseConfig = {
        apiKey: authData.firebase.api_key,
        authDomain: authData.firebase.auth_domain,
        databaseURL: authData.firebase.database_base_url,
        projectId: authData.firebase.project_id,
        storageBucket: authData.firebase.storage_bucket,
        messagingSenderId: authData.firebase.messaging_sender_id,
        appId: authData.firebase.app_id,
      };

      const firebase = initializeApp(firebaseConfig);
      const auth = getAuth(firebase);
      if (!auth.currentUser) await signInWithCustomToken(auth, authData.fCustomToken);
      const firestore = getFirestore(firebase);
      const functions = getFunctions(firebase);
      const storage = getStorage(firebase);

      setStorage(storage);
      setFunctions(functions);
      setFirestore(firestore);
      setUser(auth.currentUser);
      setConfigured(true);
    } catch (error) {
      console.error('Failed to initialize Firebase:', error);
    }
  };

  useEffect(() => {
    initializeFirebase();
  }, []);

  return (
    <FirestoreContext.Provider
      value={{ firestore, user, firebaseConfigured: configured, functions, storage }}
    >
      {children}
    </FirestoreContext.Provider>
  );
};
